import Localization from "I18n/I18n";
import MenuItem from "@mui/material/MenuItem";
import { Select, InputLabel, FormControl } from "@mui/material";
import { useState } from "react";
import OrderData from "models/order-data";

const OrderStatus = ({ status, row, getOrders }) => {
  const color = {
    ordered: "#2B93DE",
    inProgress: "#CDB15E",
    prepared: "#E48855",
    shipped: "#27A083",
    delivered: "#28B811",
    canceled: "#C25151",
    undelivered: "#C1439E",
  };
  const [statusChange, setStatusChange] = useState(status);
  const handleChange = async (e) => {
    setStatusChange(e.target.value);

    let status = e.target.value;

    await OrderData.updateStatus(row.order_id, status);
    getOrders();
  };
  return (
    <FormControl fullWidth>
      <InputLabel
        id="demo-simple-select-label"
        sx={{ color: color[statusChange] }}
      >
        status
      </InputLabel>
      <Select
        sx={{ color: color[statusChange] }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={statusChange}
        label="status"
        onChange={handleChange}
      >
        <MenuItem value="ordered">{Localization.translate["ordered"]}</MenuItem>
        <MenuItem value="inProgress">
          {Localization.translate["inProgress"]}
        </MenuItem>
        <MenuItem value="prepared">
          {Localization.translate["prepared"]}
        </MenuItem>
        <MenuItem value="shipped">{Localization.translate["shipped"]}</MenuItem>
        <MenuItem value="delivered">
          {Localization.translate["delivered"]}
        </MenuItem>
        <MenuItem value="canceled">
          {Localization.translate["canceled"]}
        </MenuItem>
        <MenuItem value="undelivered">
          {Localization.translate["undelivered"]}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
export default OrderStatus;
