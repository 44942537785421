import * as React from 'react';
import Button from '@mui/material/Button';
import { Grid, TextField } from '@mui/material';
import Localization from "I18n/I18n";
import MerchantDialog from './merchantDialog';

const MerchantsDialog=({ fields_onChange, merchantName })=> {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    setSelectedValue(merchantName);
  }, [merchantName]);
  const handleClose = (value) => {
    setOpen(false);
    if (value == null){
      return;
    }
    setSelectedValue(value.name);

    fields_onChange("merchant_id", value.merchant_id);
  };

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12} md={6}>
        <TextField
          disabled
          fullWidth
          variant="outlined"
          value={selectedValue}
          label={Localization.translate["merchant_name"]}
        />
      </Grid>
      <Grid item xs={12} md={6} alignSelf="center">
        <Button variant="outlined" onClick={handleClickOpen} fullWidth>
          {Localization.translate.choose_merchant}
        </Button>
        <MerchantDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Grid>
    </Grid>
  );
}
export default MerchantsDialog;
