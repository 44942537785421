import React, { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import Localization from "I18n/I18n";
import Auth from "models/auth";
import Popup from "pages/user-profile/change-password-dialog";
import UpdateProfile from "./components/edit-pofile";

function Title(props) {
  return (
    <Typography
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
      sx={{ ml: 2, mt: 10 }}
    >
      {props.children}
    </Typography>
  );
}
export default function UserProfile() {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Container maxWidth="lg">
      <Grid item xs={12} />
      <Paper>
        <Grid container spacing={2} alignItems="baseline">
          <Grid item xs={10}>
            <Title>{Localization.translate["profile-details"]}</Title>
          </Grid>
          <Grid item sx={2}>
            <UpdateProfile />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{Localization.translate["name"]}</TableCell>
                <TableCell>{Auth.currentUser.displayName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{Localization.translate["email"]}</TableCell>
                <TableCell>{Auth.currentUser.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button size="small" onClick={() => setOpenPopup(true)}>
                    {Localization.translate["edit_password"]}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}></Popup>
    </Container>
  );
}
