import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import {
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  Button,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
  LinearProgress,
} from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import NoImage from "assets/img/noimage.png";
import Localization from "I18n/I18n";
import ProductImageData from "models/product_image";

export default function ProductImageList(props) {
  const { onChange, productId,mainImg } = props;
  const myRef = React.useRef();
  const [productMainImage, setProductMainImage] = useState(mainImg?mainImg:null);
  const [productImage, setProductImage] = useState([]);
  const [mainImgae, setMainImage] = useState(null);
  const [alertMessage, setAlertMsg] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);

  const onSelectFile = async (event) => {
    /**
     * if there are no files, do nothing,
     */
    if (event.target.files.length <= 0) {
      return;
    }

    setAlertMsg(
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
    ProductImageData.add(event.target.files[0], productId)
      .then((result) => {
        setAlertMsg(
          <Alert
            onClose={() => {
              setAlertMsg(<></>);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            {Localization.translate["successfully_added"]}
          </Alert>
        );
        let _pImage = [...productImage];
        _pImage.push(result);
        if (mainImgae === null) {
          setMainImage(result);
        }
        setProductImage(_pImage);
        setAlertMsg(<></>);
        if (typeof onChange === "function") {
          onChange("product_image", _pImage);
        }
      })
      .catch((error) => {
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={6000}
          onClose={() => {
            setAlertMsg(<></>);
          }}
        >
          <Alert
            onClose={() => {
              setAlertMsg(<></>);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {Localization.translate[error.message]}
          </Alert>
        </Snackbar>;
      });
  };
  const mainImage_onClick = (item) => {

    setProductMainImage(item.image_url);
    if (typeof onChange === "function") {
      onChange("main_image", item);
    }
    ProductImageData.setMainImage(item, productId)
      .then((results) => {
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="success"
              sx={{ width: "100%" }}
            >
              {Localization.translate["main_image_success"]}
            </Alert>
          </Snackbar>
        );
      })
      .catch((error) => {
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              {Localization.translate["main_image_faield"]}
            </Alert>
          </Snackbar>
        );
      });
  };
  const delete_onClick = (item) => {
    setAlertMsg(
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
    ProductImageData.delete(item)
      .then((result) => {
        let _productImage = productImage.filter((pImage) => pImage !== item);
        setProductImage(_productImage);
        setAlertMsg(<></>);
        if (typeof onChange === "function") {
          onChange("product_image", _productImage);
        }
      })
      .catch((error) => {
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              {Localization.translate[error.message]}
            </Alert>
          </Snackbar>
        );
      });
  };

  useEffect(() => {
    setIsLoading(true);
    ProductImageData.getByProductId(productId)
      .then((result) => {
        setIsLoading(false);
        
        setProductImage(result.results,()=>{});
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [productId]);

  if (isLoading) {
    <Grid container>
      <Grid item xs={12}>
        <LinearProgress />
      </Grid>
    </Grid>;
  }
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignContent="center"
    >
      <Grid item xs={5}>
        <Grid item xs={12}>
          <Alert severity="info" style={{ marginBottom: "1rem" }}>
            {
              Localization.translate[
                "main-image-will-showing-in-product-preview"
              ]
            }
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardMedia
              image={
                productMainImage === null ? NoImage : productMainImage
              }
              component="img"
              height="300"
              alt="product image"
              width="500"
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <input
            type="file"
            style={{ display: "none" }}
            ref={myRef}
            onChange={onSelectFile}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              myRef.current.click();
            }}
          >
            {Localization.translate["upload"]}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid item xs={12}>
          <Alert severity="info">
            {Localization.translate["click_to_set_main_image"]}
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <ImageList sx={{ width: "100%" }}>
            {productImage.map((item) => (
              <ImageListItem
                key={item.id}
                onClick={() => {}}
                sx={{ width: "100%" }}
              >
                <Card>
                  <CardActionArea
                    onClick={() => {
                      mainImage_onClick(item);
                    }}
                  >
                    <CardMedia
                      image={item.image_url}
                      component="img"
                      height="300"
                      alt="product image"
                    />
                  </CardActionArea>
                </Card>
                <ImageListItemBar
                  title={
                    <IconButton
                      color="delete"
                      onClick={() => {
                        delete_onClick(item);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
      {alertMessage}
    </Grid>
  );
}
