const StaticData = {
  cities: [
    "all",
    "al_anbar",
    "al_qadisiyyah",
    "babil",
    "baghdad",
    "basra",
    "dhi_qar",
    "diyala",
    "duhok",
    "erbil",
    "halabja",
    "karbala",
    "kirkuk",
    "maysan",
    "muthanna",
    "najaf",
    "nineveh",
    "saladin",
    "sulaymaniyah",
    "wasit",
  ]
};
export default StaticData;
