/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, LinearProgress, Typography, TextField } from "@mui/material";
import Fade from "@mui/material/Fade";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PrintIcon from "@mui/icons-material/Print";
import Localization from "I18n/I18n";
import OrderData from "models/order-data";
import ChangeOrderStatus from "pages/order/components/change-order-status-dialog";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Numbers as NumbersIcon } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoneyIcon from "@mui/icons-material/Money";
import OrderStatus from "./components/order-status";
import DescriptionIcon from "@mui/icons-material/Description";


function ProductOptionMenu({ row }) {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = {
    color: "gray",
    display: "flex",
    lineHeight: "11px",
    fontSize: "30px",
    fontWeight: "700",
    marginTop: "-16px",
    alignItems: "center",
  };
  return (
    <>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
     
     
        <div style={options}>
          .<br />.<br />.
        </div>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            navigate("/order/detail/" + row.order_id);
          }}
        >
          <ListAltIcon />
          &nbsp;{Localization.translate["display_order_details"]}
        </MenuItem>
        <MenuItem>
          <PrintIcon />
          &nbsp;{Localization.translate["print_order_details"]}
        </MenuItem>
      </Menu>
    </>
  );
}
export default function OrderPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [alertMessage, setAlertMessage] = useState(<></>);
  const [openPopup, setOpenPopup] = useState(false);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1); //page number
  const dayjs = require("dayjs");
  const iconStyle = {
    color: "#731F1F",
  };
  const btnAdd = {
    display: "flex",
    margin: "auto",
    backgroundColor: "#9A1111",
    borderColor: "#9A1111",
    justifyContent: "center",
    padding: "6px",
  };

  const getAllOrders = () => {
    OrderData.getAll(page, search, filter)
      .then((result) => {
        setIsLoading(true);

        if (result.results.length > 0) {
          setAlertMessage(<></>);

          setOrderList(result.results);
          setIsLoading(false);
        } else {
          setOrderList([]);
          setAlertMessage(<>{Localization.translate["no_items"]}</>);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setOrderList([]);
        setAlertMessage(<>{Localization.translate["no_items"]}</>);
        setIsLoading(false);
      });
  };
  const filterStatus = (value) => {
    setSearchParams(`?${new URLSearchParams({ filterBy: 'status', filterValue: value, page: '1' })}`)
  };
  const searchOrder = () => {
    setSearchParams(`?${new URLSearchParams({ filterBy: 'all', filterValue: '', page: '1' })}`)
  };
  const nextPage = () => {
    let currentParams = Object.fromEntries([...searchParams]);
    let _currentPage = Number(currentParams['page']);
    if (isNaN(_currentPage)){
      _currentPage = 1;
    }
    currentParams['page'] = _currentPage + 1;
    setSearchParams(`?${new URLSearchParams(currentParams)}`)
  };
  const previousPage = () => {
    let currentParams = Object.fromEntries([...searchParams]);
    let _currentPage = Number(currentParams['page']);
    if (isNaN(_currentPage)){
      _currentPage = 1;
    }
    if (_currentPage > 1){
      currentParams['page'] = _currentPage - 1;
      setSearchParams(`?${new URLSearchParams(currentParams)}`)
    }
  };

  useEffect(()=>{
    const currentParams = Object.fromEntries([...searchParams]);
    if (currentParams['filterBy'] === 'status'){
      setFilter(currentParams['filterValue']);
    }
    let _page = currentParams['page'] ?? 1;
    let _filterBy = currentParams['filterBy'] ?? 'all';
    let _filterValue = currentParams['filterValue'] ?? '';
    setPage(_page);
    OrderData.getAll(_page, _filterBy, _filterValue)
      .then((result) => {
        setIsLoading(true);
        if (result.results.length > 0) {
          setAlertMessage(<></>);
          setOrderList(result.results);
          setIsLoading(false);
        } else {
          setOrderList([]);
          setAlertMessage(<>{Localization.translate["no_items"]}</>);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setOrderList([]);
        setAlertMessage(<>{Localization.translate["no_items"]}</>);
        setIsLoading(false);
      });
  },[searchParams])
  if (isLoading) {
    return (
      <React.Fragment>
        <LinearProgress />
      </React.Fragment>
    );
  }
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ width: "100%" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={2} sx={{ padding: "10px" }}>
                <TextField
                  select
                  label={Localization.translate["filter_by_status"]}
                  fullWidth
                  value={filter}
                  onChange={(e) => filterStatus(e.target.value)}
                  size="small"
                >
                  <MenuItem value={"ordered"}>
                    {Localization.translate["ordered"]}
                  </MenuItem>
                  <MenuItem value={"inProgress"}>
                    {Localization.translate["inProgress"]}
                  </MenuItem>
                  <MenuItem value={"prepared"}>
                    {Localization.translate["prepared"]}
                  </MenuItem>
                  <MenuItem value={"shipped"}>
                    {Localization.translate["shipped"]}
                  </MenuItem>
                  <MenuItem value={"delivered"}>
                    {Localization.translate["delivered"]}
                  </MenuItem>
                  <MenuItem value={"canceled"}>
                    {Localization.translate["canceled"]}
                  </MenuItem>
                  <MenuItem value={"undelivered"}>
                    {Localization.translate["undelivered"]}
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid xs={12} md={10} container item>
                <Grid item xs={12} md={6} sx={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    id="search-input"
                    variant="outlined"
                    label={
                      Localization.translate["write_what_do_you_want_to_search"]
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ padding: "10px" }}>
                  <Button
                    variant="contained"
                    style={btnAdd}
                    onClick={searchOrder}
                  >
                    {Localization.translate["search_btn"]}
                  </Button>
                </Grid>
                <Grid item xs={12} md={2} sx={{ padding: "10px" }}>
                  <TextField
                    select
                    label={Localization.translate["search_by"]}
                    fullWidth
                    value={searchBy}
                    onChange={(e) => {
                      setSearchBy(e.target.value);
                    }}
                    size="small"
                  >
                    <MenuItem value={"datetime"}>
                      {Localization.translate["date"]}
                    </MenuItem>
                    <MenuItem value={"time"}>
                      {Localization.translate["time"]}
                    </MenuItem>
                    <MenuItem value={"status"}>
                      {Localization.translate["order_status"]}
                    </MenuItem>
                    <MenuItem value={"address"}>
                      {Localization.translate["address"]}
                    </MenuItem>
                    <MenuItem value={"amount"}>
                      {Localization.translate["amount"]}
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={iconStyle}>
                    <Grid item sx={{ display: "flex" }}>
                      <Typography marginRight="0.5rem">
                        {Localization.translate["order_number"]}
                      </Typography>
                      <NumbersIcon style={iconStyle} />
                    </Grid>
                  </TableCell>
                  <TableCell style={iconStyle}>
                    <Grid item sx={{ display: "flex" }}>
                      <Typography marginRight="0.5rem">
                        {Localization.translate["date"]}
                      </Typography>
                      <CalendarMonthIcon style={iconStyle} />
                    </Grid>
                  </TableCell>
                  <TableCell style={iconStyle}>
                    <Grid item sx={{ display: "flex" }}>
                      <Typography marginRight="0.5rem">
                        {Localization.translate["time"]}
                      </Typography>
                      <AccessTimeIcon />
                    </Grid>
                  </TableCell>
                  <TableCell style={iconStyle}>
                    <Grid item sx={{ display: "flex" }}>
                      <Typography marginRight="0.5rem">
                        {Localization.translate["order_status"]}
                      </Typography>
                      <DescriptionIcon />
                    </Grid>
                  </TableCell>
                  <TableCell style={iconStyle}>
                    <Grid item sx={{ display: "flex" }}>
                      <Typography marginRight="0.5rem">
                        {" "}
                        {Localization.translate["address"]}
                      </Typography>
                      <LocationOnIcon style={iconStyle} />
                    </Grid>
                  </TableCell>
                  <TableCell style={iconStyle}>
                    <Grid item sx={{ display: "flex" }}>
                      <Typography marginRight="0.5rem">
                        {" "}
                        {Localization.translate["amount"]}
                      </Typography>
                      <MoneyIcon />
                    </Grid>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderList.map((row) => (
                  <TableRow key={row.order_id}>
                    <TableCell>
                      {row.order_id}
                    </TableCell>
                    <TableCell>
                      {dayjs(row.create_datetime).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>
                      {dayjs(row.create_datetime).format("HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <OrderStatus
                        status={row.status}
                        row={row}
                        getOrders={getAllOrders}
                      />
                    </TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>
                      <ProductOptionMenu row={row} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <ChangeOrderStatus
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            ></ChangeOrderStatus>
            <Typography style={{ textAlign: "left" }}>
              <Button onClick={previousPage}>
                {Localization.translate["previous"]}
              </Button>
              <Button onClick={nextPage}>
                {Localization.translate["next"]}
              </Button>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {alertMessage}
        </Grid>
      </Grid>
    </Container>
  );
}
