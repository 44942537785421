import firebaseApp from "components/Firebase/firebase";
import {
  getFirestore,
  query,
  collection,
  orderBy,
  getDocs,
  doc,
  setDoc,
} from "firebase/firestore";
export class CostTable {
  constructor(id, data) {
    this.id = id;
    this.cost = data["cost"];
  }
}
const DeliveryCostData = {
  async getDeliveryCost() {
    try {
      let db = getFirestore(firebaseApp);
      let DCQuery = query(collection(db, "delivery_cost"), orderBy("cost"));
      let data = await getDocs(DCQuery);

      if (data.docs.length <= 0) {
        return Promise.reject({ error: 14, message: "No Delivery Cost" });
      }
      let listCost = [];
      for (var i = 0; i < data.docs.length; i++) {
        //get order
        let costTable = new CostTable(data.docs[i].id, data.docs[i].data());
        listCost.push(costTable);
      }
      return Promise.resolve(listCost);
    } catch (error) {
      return Promise.reject({ error: 14, message: "No Delivery Cost" });
    }
  },
  async updateDeliveryCost(id, cost) {
    let db = getFirestore(firebaseApp);

    const docRef = doc(db, "delivery_cost", id);
    await setDoc(docRef, cost, { merge: true });
  },
};
export default DeliveryCostData;
