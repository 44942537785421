import { useState } from "react";
import { Modal, Box, Typography, Button, Grid, Container } from "@mui/material";
import MerchantsData from "models/merchant-data";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Localization from "I18n/I18n";

const DeleteMerchant = ({ row, getAllMerchants }) => {
  const [open, setOpen] = useState(false);
  const modalStyle = {
    width: "45rem",
    padding: " 1rem",
    margin: "auto",
    backgroundColor: "white",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15rem",
    border: "none",
    outline: "none",
  };
  const cancelStyle = {
    display: "flex",
    fontWeight: "600",
    fontSize: "12px",
    color: "#9A1111",
    justifyContent: "end",
  };
  const yesBtn = {
    borderRadius: "26px",
    padding: "3px 50px",
  };
  const deleteMerchant = async (row) => {
    await MerchantsData.deleteMerchant(row.merchant_id);
    handleClose();
    getAllMerchants();
  };
  const handleOpen = (index) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="add an alarm"
        onClick={handleOpen}
        style={cancelStyle}
      >
        <DeleteIcon />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={modalStyle}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {Localization.translate["Confirm_deletion_merchant"]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{row.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{row.address}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>{row.phone_number}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{row.secondary_phone_number}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{row.phone}</Typography>
              </Grid>
            </Grid>
          </Container>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 40 }}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="error"
                  style={yesBtn}
                  onClick={() => {
                    deleteMerchant(row);
                  }}
                >
                  {Localization.translate["yes"]}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="success"
                  variant="outlined"
                  style={yesBtn}
                  onClick={handleClose}
                >
                  {Localization.translate["no"]}
                </Button>
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default DeleteMerchant;
