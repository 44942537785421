import React from 'react';
import {Typography, Link} from '@mui/material';

export default function Copyright(props) {
    const {short} = props;
    if (short){
        return (
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                <Link color="inherit" href="http://lightringtechnology.com/">
                    {'Light Ring Technology © '}
                </Link>{' '}
            </Typography>
          );
    }
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
          {'Copyright © '}
          <Link color="inherit" href="http://lightringtechnology.com/">
            Light Ring Technology
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      );
}