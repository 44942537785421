// import { getStorage, ref, getDownloadURL } from "firebase/storage";
import axios from "axios";
import { deviceTokenEndpoint } from "configs/endpoints";
import Auth from "./auth";

export class DeviceTokenModel {
  /**
   * function to save the device token for the current user
   * @param {String} deviceToken
   * @returns {Promsie<JSON>}
   */
  async saveDeviceToken(deviceToken){
    try {
      let saveToken = await axios({
        // Endpoint to send files
        url: deviceTokenEndpoint,
        method: 'POST',
        data:{ device_token: deviceToken },
        headers: {
          "Content-Type": "application/json",
          authorization: Auth.currentUser.accessToken,
        },
      });
 

      return Promise.resolve(saveToken);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  /**
   * function to delete the device token for the current user
   * @param {String} deviceToken
   * @returns {Promsie<JSON>}
   */
  async deleteDeviceToken(deviceToken){
    try {
      let deleteToken = await axios({
        // Endpoint to send files
        url: deviceTokenEndpoint,
        method: 'DELETE',
        data:{ device_token: deviceToken },
        headers: {
          "Content-Type": "application/json",
          authorization: Auth.currentUser.accessToken,
        },
      });
 

      return Promise.resolve(deleteToken);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}

DeviceTokenModel = new DeviceTokenModel();
export default DeviceTokenModel;
