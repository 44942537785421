import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SaveIcon from "@mui/icons-material/Save";
import {
  Paper,
  Grid,
  StepButton,
  Alert,
  Snackbar,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import useMediaQuery from "@mui/material/useMediaQuery";

import ProductImageList from "../../components/product-image-list/product_image_list";
import ProductCompatible from "../../components/product-fit/product_compatible";
import ProductDetails from "../../components/product-details/product-details";
import Localization from "I18n/I18n";
import ProductData from "models/product-data";

const steps = [
  `${Localization.translate["product_detail"]}`,
  `${Localization.translate["product_compatible"]}`,
  `${Localization.translate["product_image"]}`,

];

export default function AddProductPage() {
  const isSmallDevice = useMediaQuery("(max-width:600px)");
  /**
   * there are 4 steps begin with
   *  0 -> add product details
   *  1 -> add product fit (compatible)
   *  2 -> add product images
   *  3 -> add merchant to product
   * every step depend on first step (step 0)
   */
  const [activeStep, setActiveStep] = React.useState(0);
  /**
   * only field pass to this page,
   */
  const [productFields, setProductFields] = React.useState({
    product_id: null,
    name: "",
    price: 0,
    description: "",
    barcode: "",
    product_width: 0,
    product_height: 0,
    product_length: 0,
    package_weight: 0,
    part_number: "",
    sku_number: "",
    category_id: "",
    quantity: 1,
  });
  const [alertMsg, setAlertMsg] = React.useState(<></>);

  const handleNext = async () => {
    /**
     * If the step is the first step then don't go to next step unless the product id is set,
     */
    if (activeStep === 0) {
      /**
       * Set loading to make sure user does not miss click
       */
      setAlertMsg(
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
      /**
       * Request API to add new product.
       */
      ProductData.addNew(productFields)
        .then((result) => {
          setAlertMsg(<></>);
          let _pDetails = { ...productFields };
          _pDetails.product_id = result.results.product_id;
          setProductFields(_pDetails);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch((error) => {
          /**
           * handle error message, and convert it to localization
           */
          setAlertMsg(
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={true}
              autoHideDuration={6000}
              onClose={() => {
                setAlertMsg(<></>);
              }}
            >
              <Alert
                onClose={() => {
                  setAlertMsg(<></>);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                {Localization.translate[error.message]}
              </Alert>
            </Snackbar>
          );
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    /**
     * We can't rollback to add product,
     */
    if (activeStep - 1 === 0) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const publishProduct = () => {
    ProductData.available(productFields.product_id, true)
      .then((success) => {
        /**
         * show success message
         */
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="success"
              sx={{ width: "100%" }}
            >
              {Localization.translate.sucessfully_pusblished}
            </Alert>
          </Snackbar>
        );
      })
      .catch((error) => {
        /**
         * handle error message, and convert it to localization
         */
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              {Localization.translate[error.message]}
            </Alert>
          </Snackbar>
        );
      });
  };
  const handleStep = (step) => () => {
    if (activeStep === 0) {
      return;
    }
    step !== 0 && setActiveStep(step);
  };
  return (
    <Grid container spacing={2} sx={{ padding: "10px" }}>
      <Grid item xs={12}>
        {isSmallDevice ? (
          <>
            <Paper
              square
              elevation={0}
              sx={{
                display: "flex",
                alignItems: "center",
                height: 50,
                pl: 2,
                bgcolor: "background.default",
              }}
            >
              <Typography>{steps[activeStep]}</Typography>
            </Paper>
            <MobileStepper
              variant="text"
              steps={4}
              position="static"
              activeStep={activeStep}
            />
          </>
        ) : (
          <Stepper
            nonLinear
            activeStep={activeStep}
            style={{ marginBottom: "3%", marginTop: "10px" }}
          >
            {steps.map((label, index) => {
              const stepProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton onClick={handleStep(index)}>{label}</StepButton>
                </Step>
              );
            })}
          </Stepper>
        )}
      </Grid>
      <Grid item xs={12}>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={publishProduct}>Reset</Button>
            </Box>
          </>
        ) : (
          <React.Fragment>
            {/* 
                First Step is to add product details and get product id, 
                other steps depend on product id,
              */}
            {activeStep === 0 && (
              <ProductDetails
                onChange={(values) => {
                  setProductFields(values);
                }}
              />
            )}
            {/* 
                Second Step is to add car compatible with product, 
                this step depend on product id if there is no id then this step will failed
              */}
            {activeStep === 1 && (
              <ProductCompatible productId={productFields.product_id} />
            )}
            {/* 
                Third Step add image to product
              */}
            {activeStep === 2 && (
              <ProductImageList productId={productFields.product_id} />
            )}
            {/* 
                this step is to add merchant to product
              */}
          
            <Grid item xs={12}>
              {alertMsg}
            </Grid>
            <Grid item xs={12}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {Localization.translate["back"]}
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button onClick={publishProduct}>
                  {Localization.translate["publish"]}
                </Button>
              ) : activeStep === 0 ? (
                <Button onClick={handleNext}>
                  <SaveIcon />
                  &nbsp;{Localization.translate["save"]}&
                  {Localization.translate["continue"]}
                </Button>
              ) : (
                <Button onClick={handleNext}>
                  {Localization.translate["continue"]}
                </Button>
              )}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
