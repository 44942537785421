import apiURL from "configs/configs";
import Auth from "./auth";

const axios = require("axios").default;
const baseUrl = apiURL + "product/";

//Default export here
const ProductData = {
  async addNew(record) {
    try {
      let data = record;

      //apiURL
      let response = await axios.post(baseUrl, data, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject({ error: 76, message: "added_failed" });
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async discount(id, number) {
    let data = { discount: number };

    var config = {
      method: "patch",
      url: `${baseUrl}${id}/discount`,
      headers: {
        Authorization: Auth.currentUser.accessToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch(function (error) {
        return Promise.reject(error.response.data);
      });
  },
  async getAll(page, filterValue, filterBy) {
    try {
      //apiURL
      let url =
        baseUrl +
        `?page=${page}&filterBy=${filterBy}&filterValue=${filterValue}`;
      let response = await axios.get(encodeURI(url), {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async available(id, isAvailable) {
    let data = { is_available: isAvailable };
    try {
      let response = await axios.patch(`${baseUrl}${id}/available`, data, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getById(id) {
    try {
      //apiURL
      let response = await axios.get(baseUrl + `${id}`, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async deleteById(id) {
    try {
      //apiURL
      let response = await axios.delete(baseUrl + `${id}`, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  //////////////////////////
  //Car Fit section
  //////////////////////////
  async getCarFit(id) {
    try {
      //apiURL
      let response = await axios.get(baseUrl + `${id}/fit`, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async addProductFit(productId, productFit) {
    try {
      //apiURL
      let response = await axios.post(
        baseUrl + `${productId}/fit`,
        productFit,
        {
          headers: {
            authorization: Auth.currentUser.accessToken,
          },
        }
      );
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }
      return Promise.resolve(result.results);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async deleteCarFit(id, productFitId) {
    try {
      //apiURL
      let response = await axios.delete(baseUrl + `${id}/fit/${productFitId}`, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.results.success === false) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject({ message: "delete_faield" });
    }
  },
  async update(id,record) {
    try {
     
      let data = record;

      //apiURL
      let response = await axios.patch(baseUrl + `${id}`, data, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject({ error: 76, message: "update_failed" });
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async merchantProduct(id, merchant) {
    try {
      let response = await axios.post(`${baseUrl}${id}/merchant`, merchant, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject({ message: "added_faield" });
    }
  },
  async deleteMerchantProduct(id, merchantID) {
    try {
      let response = await axios.delete(
        `${baseUrl}${id}/merchant/${merchantID}`,
        {
          headers: {
            authorization: Auth.currentUser.accessToken,
          },
        }
      );
      var result = response.data;

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject({ message: "delete_faield" });
    }
  },
  async getMerchantProduct(id) {
    try {
      let response = await axios.get(`${baseUrl}${id}/merchant`, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;

      if (result.results.length > 0) {
        return Promise.resolve(result);
      } else {
        return Promise.reject({ message: "get_faield" });
      }
    } catch (error) {
      return Promise.reject({ message: "get_faield" });
    }
  },
 
};

export default ProductData;
