import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Button, Typography, Alert, LinearProgress } from "@mui/material";
import Localization from "I18n/I18n";
import Popup from "./components/update-cost";
import DeliveryCostData from "models/delivery-cost";

function Title(props) {
  return (
    <Typography
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
      sx={{ ml: 2, mt: 10 }}
    >
      {props.children}
    </Typography>
  );
}
export default function Setting() {
  const [cost, setCost] = useState(0);
  const [id, setId] = useState("");

  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(<></>);
  const deliveryCost = () => {
    setIsLoading(true);
    DeliveryCostData.getDeliveryCost()
      .then((data) => {
        setAlertMessage(<></>);
        setId(data[0]?.id);
        setCost(data[0]?.cost);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setAlertMessage(
          <Alert severity="error">{Localization.translate["no_items"]}</Alert>
        );
      });
  };
  useEffect(() => {
    deliveryCost();
  }, []);
  if (isLoading) {
    return (
      <React.Fragment>
        <LinearProgress />
      </React.Fragment>
    );
  }
  return (
    <Container maxWidth="lg">
      <Grid item xs={12} />
      <Paper>
        <Grid container spacing={2} alignItems="baseline">
          <Grid item xs={10}>
            <Title>{Localization.translate["setting"]}</Title>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{Localization.translate["delivery_cost"]}</TableCell>
                <TableCell>{cost}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button size="small" onClick={() => setOpenPopup(true)}>
                    {Localization.translate["edit_delivery_cost"]}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          {alertMessage}
        </Grid>
      </Paper>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        cost={cost}
        id={id}
        deliveryCost={deliveryCost}
      ></Popup>
    </Container>
  );
}
