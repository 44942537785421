import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Localization from 'I18n/I18n';

export default function ChangeOrderStatus(props){
    const {openPopup, setOpenPopup} = props;
    const handleClose = () => {
        setOpenPopup(false);
      };
    return(
      <Dialog open={openPopup} onClose={handleClose}>
      <DialogTitle>
      {Localization.translate["change_order_status"]}
      </DialogTitle>
      <DialogContent dividers>
         <TextField
            select
            label={Localization.translate["order_status"]}
            fullWidth
            //value={orderstatus}
            //onChange={handleChange}
            size="small"
            >
            <MenuItem value={1}>{Localization.translate["ordered"]}</MenuItem>
            <MenuItem value={2}>{Localization.translate["prepared"]}</MenuItem>
            <MenuItem value={3}>{Localization.translate["shipped"]}</MenuItem>
            <MenuItem value={4}>{Localization.translate["delivered"]}</MenuItem>
            <MenuItem value={5}>{Localization.translate["canceled"]}</MenuItem>
            <MenuItem value={6}>{Localization.translate["undelivered"]}</MenuItem>
            <MenuItem value={7}>{Localization.translate["other"]}</MenuItem>
        </TextField>
        <TextField
            autoFocus
            margin="dense"
            id="name"
            label={Localization.translate["enter_other_order_status"]}
            type="password"
            fullWidth
            variant="standard"
          />
      </DialogContent>
      <DialogActions>
              <Button onClick={handleClose}>{Localization.translate["cancel"]}</Button>
              <Button onClick={handleClose}>{Localization.translate["submit"]}</Button>
            </DialogActions>
      </Dialog>
    )
   
  }
     