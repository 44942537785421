import * as React from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import { DialogContent, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import Localization from "I18n/I18n";
import MerchantsData from "models/merchant-data";

const styles = {
  dialogPaper: {
    height: "500px",
    width: "50px",
  },
};

const MerchantDialog = ({ onClose, selectedValue, open }) => {
  const [search, setSearch] = React.useState("");
  const [merchants, setMerchants] = React.useState([]);
  const getAllMerchants = (page, value) => {
    MerchantsData.getAllMerchants(page, value)
      .then((data) => {
        setMerchants(data.results);
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    onClose(null);
  };

  const handleListItemClick = (merchant) => {
    onClose(merchant);
  };
  React.useEffect(() => {
    getAllMerchants("1", "");
  }, []);
  React.useEffect(() => {
    getAllMerchants("all", search);
  }, [search]);
  return (
    <Dialog 
      fullWidth 
      maxWidth="sm"
      scroll="paper"
      onClose={handleClose} 
      open={open} 
      >
      <DialogTitle>
        <TextField
          sx={{
            display: "flex",
            margin: "auto",
            justifyContent: "flex-end",
          }}
          id="search-input"
          variant="outlined"
          label={Localization.translate["search _about_merchant"]}
          size="small"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </DialogTitle>
      <DialogContent>
        <List sx={{ pt: 0, maxHeight: "400px" }}>
          {merchants.map((merchant) => (
            //TODO: @deprecated — checkout ListItemButton instead
            <ListItem
              button
              onClick={() => handleListItemClick(merchant)}
              key={merchant.merchantId}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={merchant.name}
                secondary={
                  <>
                    <Typography component="span" variant="body2">{merchant.address}</Typography>
                    <Typography component="div" variant="body2">{merchant.phone_number}</Typography>
                  </>
                }
                />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
// export default MerchantDialog
export default withStyles(styles)(MerchantDialog);
