import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD6p9aXm6Q98TdRDtjS_c3V1m64zm0lm94",
  authDomain: "auto-spare-iq.firebaseapp.com",
  projectId: "auto-spare-iq",
  storageBucket: "auto-spare-iq.appspot.com",
  messagingSenderId: "930808092965",
  appId: "1:930808092965:web:e6a1f2c11c6a877a33e2cc",
  measurementId: "G-JZELBSE17K",
};

const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp);
//Foreground Listener Setup
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BBrBtVbKbirZtNx0V8WS4JczUaOqZ0QemZBf886mLh2ERrTR87XLaVArWVzYzV2CBJ8G-dpu1GUKhR03cmgy3oA",
  })
    .then(async (currentToken) => {
      return currentToken
    })
    .catch((err) => {return null});
};
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      //Registration successful, scope is: registration.scope
      requestForToken();
    });
}
export default firebaseApp;
