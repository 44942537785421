import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "pages/home/home";
import OrderPage from "pages/order/order-page";
import ProductPage from "pages/product/product-page";
import DeleteProductPage from "pages/delete-product/delete-product-page";
import UserProfile from "pages/user-profile/user-profile";
import OrderDetail from "pages/order-detail/order-detail";
import MerchantPage from "pages/merchants/merchant-page";
import Setting from "pages/setting/setting";
import AddProduct from "pages/add_product/add-product";
import ApiEditPage from "pages/edit-page/edit-page";
import AddMerchant from "pages/merchants/add-merchant/index";
import OrdersRequested from "pages/orders-requested/index";
import CardComponent from "pages/orders-requested/cardComponent";
export default function RoutesConfig() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/merchants" element={<MerchantPage />} />
      <Route exact path="/merchants/add" element={<AddMerchant />} />
      <Route exact path="/merchants/update/:id" element={<AddMerchant />} />
      <Route exact path="/order" element={<OrderPage />} />
      <Route exact path="/order/detail/:id" element={<OrderDetail />} />
      <Route exact path="/" element={<Home />} />
      <Route exact path="/ordersRequested" element={<OrdersRequested />} />
      <Route exact path="/ordersRequested/:id" element={<CardComponent />} />
      <Route exact path="/product" element={<ProductPage />} />
      <Route exact path="/setting" element={<Setting />} />
      <Route exact path="/product/new" element={<AddProduct />} />
      <Route exact path="/product/delete/:id" element={<DeleteProductPage />} />
      {/* <Route exact path="/product/edit/:id" element={<EditProductPage />} /> */}
      <Route exact path="/product/edit/:id" element={<ApiEditPage />} />
      <Route exact path="/profile" element={<UserProfile />} />
    </Routes>
  );
}
