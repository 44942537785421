import { getFirestore, doc, updateDoc } from "firebase/firestore";
import firebaseApp from "components/Firebase/firebase";

const ProfileData = {
  async updateProfile(data) {
    let db = getFirestore(firebaseApp);
    const merchantDoc = doc(db, "seller", data.id);

    await updateDoc(merchantDoc, data);
  },
};
export default ProfileData;
