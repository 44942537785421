import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from '@mui/material/Alert';

import Localization from "I18n/I18n";
import Auth from "models/auth";
import { CircularProgress } from "@mui/material";

export default function Popup(props) {
  const { openPopup, setOpenPopup } = props;
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [retypeNewPassword, setRetypeNewPassword] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState(<></>);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClose = () => {
    setOpenPopup(false);
  };
  const changePassword = () => {
    if(oldPassword.length < 1){
      setAlertMsg(<Alert severity="error">{Localization.translate.invalid_old_password}</Alert>);
      return;
    }
    if(newPassword.length < 1){
      setAlertMsg(<Alert severity="error">{Localization.translate.invalid_new_password}</Alert>);
      return;
    }
    if(newPassword !== retypeNewPassword){
      setAlertMsg(<Alert severity="error">{Localization.translate.retype_password_not_match}</Alert>)
      return;
    }
    setIsLoading(true);
    Auth.changePassword(oldPassword, newPassword)
      .then((success) => {
        if (success) {
          setAlertMsg(<Alert severity="success">{Localization.translate.password_changed_successfully}</Alert>)
        }
      })
      .catch((error) => {
        setAlertMsg(<Alert severity="error">{Localization.translate[error.message]}</Alert>)
      })
      .finally(()=>{
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={openPopup} onClose={handleClose}>
      <DialogTitle>{Localization.translate["edit_password"]}</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          type="password"
          fullWidth
          variant="standard"
          label={Localization.translate["enter-your-current-password"]}
          value={oldPassword}
          onChange={(e)=>{setOldPassword(e.target.value)}}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          type="password"
          fullWidth
          variant="standard"
          label={Localization.translate["enter-new-password"]}
          value={newPassword}
          onChange={(e)=>{setNewPassword(e.target.value)}}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          type="password"
          fullWidth
          variant="standard"
          label={Localization.translate["retype-new-password"]}
          value={retypeNewPassword}
          onChange={(e)=>{setRetypeNewPassword(e.target.value)}}
        />
        {alertMsg}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {Localization.translate["cancel"]}
        </Button>
        {
          isLoading ? (
            <Button>
              <CircularProgress />
            </Button>
          ):(
            <Button variant="contained" color="primary" onClick={changePassword}>
              {Localization.translate["submit"]}
            </Button>
          )
        }
        
      </DialogActions>
    </Dialog>
  );
}
