import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import Badge from '@mui/material/Badge';
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ExtensionIcon from "@mui/icons-material/Extension";
import UserIcon from "@mui/icons-material/Person";
import { Link } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import Localization from "I18n/I18n";
import Auth from "models/auth";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function Header(props) {
  let navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertMsg, setAlertMsg] = useState(<></>);

  const goTo = (path) => {
    navigate(path);
  };
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const menu_onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menu_onClose = (item) => {
    setAnchorEl(null);
    switch (item) {
      case "signout":
        setAlertMsg(
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        );
        Auth.signOut()
          .then((suceess) => {
            setAlertMsg(<></>);
            //for now reload page.
            window.location.reload();
          })
          .catch((error) => {
            setAlertMsg(<></>);
          });
        break;
      default:
      //do nothing
    }
  };
  useEffect(() => {}, [openDrawer]);

  return (
    <React.Fragment>
      {alertMsg}
      <AppBar
        position="absolute"
        open={openDrawer}
        sx={{ displayPrint: "none" }}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(openDrawer && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {Localization.translate["dashboard"]}
          </Typography>
          {/* notifications is out of the scope */}
          {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          <IconButton color="inherit" onClick={menu_onClick}>
            <UserIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={menu_onClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                menu_onClose();
                goTo("/profile");
              }}
            >
              {Localization.translate["profile"]}
            </MenuItem>
            <MenuItem
              onClick={() => {
                menu_onClose("signout");
              }}
            >
              {Localization.translate["sign_out"]}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={openDrawer}
        sx={{ displayPrint: "none" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronRightIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              goTo("/");
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={Localization.translate["home"]} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              goTo("/merchants");
            }}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={Localization.translate["merchants"]} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              goTo("/order");
            }}
          >
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary={Localization.translate["orders"]} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              goTo("/product");
            }}
          >
            <ListItemIcon>
              <ExtensionIcon />
            </ListItemIcon>
            <ListItemText primary={Localization.translate["products"]} />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              goTo("/ordersRequested");
            }}
          >
            <ListItemIcon>
              <AddShoppingCartIcon />
            </ListItemIcon>
            <ListItemText
              primary={Localization.translate["orders-requested"]}
            />
          </ListItem>
          {/* <ListItem
            button
            onClick={() => {
              goTo("/setting");
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={Localization.translate["setting"]} />
          </ListItem> */}
        </List>
        <Divider />
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          {...props}
        >
          <Link
            color="inherit"
            href="http://lightringtechnology.com/"
            target="_blank"
          >
            {openDrawer ? "Light Ring Technology © " : "LRT©"}
          </Link>
        </Typography>
      </Drawer>
    </React.Fragment>
  );
}
export default Header;
