import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Localization from "I18n/I18n";
import DeliveryCostData from "models/delivery-cost";

export default function Popup(props) {
  const { openPopup, setOpenPopup } = props;
  const [newCost, setNewCost] = React.useState(props.cost);
  const handleClose = () => {
    setOpenPopup(false);
  };
  const handleSubmit = async () => {
    await DeliveryCostData.updateDeliveryCost(props.id, { cost: newCost });
    props.deliveryCost();
    setOpenPopup(false);
  };
  return (
    <Dialog open={openPopup} onClose={handleClose}>
      <DialogTitle>{Localization.translate["edit_delivery_cost"]}</DialogTitle>
      <DialogContent dividers>
        <TextField
          autoFocus
          margin="dense"
          id="newCost"
          label={Localization.translate["enter-new-cost"]}
          type="number"
          fullWidth
          variant="standard"
          value={newCost}
          onChange={(e) => {
            setNewCost(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {Localization.translate["cancel"]}
        </Button>
        <Button onClick={handleSubmit}>
          {Localization.translate["submit"]}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
