/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


import ProductData from "models/product-enquiry";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Localization from "I18n/I18n";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function OrdersRequested() {
  const navigate = useNavigate();
  const [enquiries, setEnquires] = React.useState([]);
  const [alertMessage, setAlertMessage] = React.useState(<></>);
  React.useEffect(() => {
    ProductData.getAllProductsEnquiry()
      .then((data) => {
        setEnquires(data.results);
      })
      .catch((error) => {        setEnquires([]);
        setAlertMessage(<>{Localization.translate["no_items"]}</>);});
  }, []);
  return (
    <Grid container spacing={2}>
    <TableContainer component={Paper} sx={{ marginTop: 10 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              {Localization.translate["product_name"]}
            </TableCell>
            <TableCell align="center">
              {Localization.translate["car_details"]}
            </TableCell>
            <TableCell align="center">
              {Localization.translate["product_images"]}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enquiries?.map((row) => (
            <TableRow
              key={row.product_enquiry_id}
              onClick={() =>
                navigate(`/ordersRequested/${row.product_enquiry_id}`, {
                  state: { row },
                })
              }
            >
              <TableCell align="center"> {row.name}</TableCell>
              <TableCell align="center">
                {row.car_year +
                  ", " +
                  row.car_maker +
                  ", " +
                  row.car_model +
                  " , " +
                  row.car_trim}
              </TableCell>
              <TableCell align="center">
                <Grid container spacing={2}>
                  {row?.product_enquiry_image.map((img) => (
                    <Grid item xs={4}>
                      <img
                        src={img.image_url}
                        alt="product image"
                        style={{ Width: "150px", height: "150px" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
              <TableCell>
                <MoreVertIcon />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
         <Grid item xs={12}>
         {alertMessage}
       </Grid>
    </Grid>
  );
}
