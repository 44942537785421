import React, { useState } from "react";
import {
  Container,
  Grid,
  Divider,
  Button,
  Alert,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  Warning as WarningIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useParams, useNavigate } from "react-router-dom";

import Localization from "I18n/I18n";
import ProductData from "models/product-data";

export default function DeleteProductPage() {
  let { id } = useParams();
  let navigate = useNavigate();
  const [alertMsg, setAlertMsg] = useState(<></>);

  const delete_onClick = () => {
    setAlertMsg(
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
    ProductData.deleteById(id)
      .then((success) => {
        navigate("/product", { replace: true });
      })
      .catch((error) => {
        setAlertMsg(
          <Alert
            onClose={() => {
              setAlertMsg(<></>);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {Localization.translate[error.message]}
          </Alert>
        );
      });
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper>
        <Grid container sx={{ padding: "10px" }}>
          <Grid item xs={12}>
            <WarningIcon />
            &nbsp;{Localization.translate["warning"]}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {Localization.translate["delete_permanent_warning"]},&nbsp;
            {Localization.translate["info_will_be_delete"]}:
          </Grid>
          <Grid item xs={12}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>*</ListItemAvatar>
                <ListItemText
                  primary={Localization.translate["product_detail"]}
                  secondary={
                    <React.Fragment>
                      {Localization.translate["product_name"]},{" "}
                      {Localization.translate["price"]} ...
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>*</ListItemAvatar>
                <ListItemText
                  primary={Localization.translate["product_compatible"]}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>*</ListItemAvatar>
                <ListItemText
                  primary={Localization.translate["product_images"]}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
          </Grid>
          <Grid item xs={12}>
            {alertMsg}
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button variant="contained" color="delete" onClick={delete_onClick}>
              <DeleteIcon />
              &nbsp;{Localization.translate["delete"]}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
