import { requestForToken } from "components/Firebase/firebase";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import  DeviceTokenModel  from 'models/device-token';
const Auth = {
  isSignIn: false,
  currentUser: null,
  merchant: null,
  init(callback) {
    const _auth = getAuth();
    onAuthStateChanged(_auth, async (user) => {
      if (user) {
         // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        Auth.currentUser = user;
        Auth.isSignIn = true;
        // const t = await user.getIdTokenResult();
        // console.log(t)
        // let's git merchant info for that user.
        //Auth.getMerchant(user.uid)
        // .then((data)=>{
        //     callback(true);
        // })
        // .catch((erro)=>{
        //     callback(false);
        // });
        callback(true);
      } else {
        Auth.currentUser = null;
        Auth.isSignIn = false;
        callback(false);
      }
    });
  },
  getToken() {
    const _auth = getAuth();
    onAuthStateChanged(_auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        Auth.currentUser = user;
        Auth.isSignIn = true;
      } else {
        Auth.currentUser = null;
        Auth.isSignIn = false;
      }
      return Auth.currentUser.accessToken;
    });
  },
  signIn(email, password) {
    return new Promise((resolve, reject) => {
      const _auth = getAuth();
      signInWithEmailAndPassword(_auth, email, password)
        .then(async(userCredential) => {
          // Signed in
          this.user = userCredential.user;
          this.isSignIn = true;
          const deviceToken = await requestForToken();
          if(deviceToken){
            try{
              this.currentUser = {...this.currentUser,device_token:deviceToken}
              await DeviceTokenModel.saveDeviceToken(deviceToken)
            }catch(err){}
          }
          return resolve(true);
        })
        .catch((error) => {
          let errorCode = "unknow_error";
          switch (error.code) {
            case "auth/invalid-email":
              errorCode = "invalid_email";
              break;
            case "auth/wrong-password":
              errorCode = "wrong_password";
              break;
            case "auth/too-many-requests":
              errorCode = "password_too_many_requests";
              break;
            default:
              errorCode = "unknow_error";
          }
          return reject({ error: 1, message: errorCode });
        });
    });
  },
  async signOut() {
    const _auth = getAuth();
    try {
      const deviceToken = await requestForToken();
      if(deviceToken){
        try{
          await DeviceTokenModel.deleteDeviceToken(deviceToken)
        }catch(err){

        }
      }
     
      signOut(_auth);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async changePassword(oldPassword, NewPassword){
    try {
      const auth = getAuth()
      const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          oldPassword
      )
      await reauthenticateWithCredential(
          auth.currentUser, 
          credential
      )
      await updatePassword(auth.currentUser, NewPassword);
      return Promise.resolve(true);
    }catch(error){
      switch(error.code){
        case 'auth/weak-password':
          return Promise.reject({error: 123, message: 'weak_password'});
        case 'auth/wrong-password':
          return Promise.reject({error: 123, message: 'invalid_old_password'});
        case 'auth/too-many-requests':
          return Promise.reject({error: 123, message: 'too_many_requests'});
        case 'INVALID_PASSWORD': 
          return Promise.reject({error: 123, message: 'invalid_old_password'});
        case 'auth/requires-recent-login':
          
          break;
        default:
          return Promise.reject({error: 123, message: 'change_password_failed'});
      }
    }
  }
  // async getMerchant(userId){
  //     try {
  //         let db = getFirestore(firebaseApp);
  //         let q =  doc(db, "merchant_user", userId);;//query(collection(db, "merchant_user"), where("user_id", "==", userId));
  //         let data = await getDoc(q);
  //         if (!data.exists()){
  //             return false;
  //         }
  //         let merchantId = data.data().merchant_id;
  //         let mData = await getDoc(doc(db, 'seller', merchantId))
  //         if (mData.exist){
  //             return false;
  //         }
  //         Auth.merchant = {
  //             "id": merchantId,
  //             "name": mData.data()["name"],
  //             "delivery_cost": mData.data()["delivery_cost"]
  //         }
  //         return false
  //     }catch(err){
  //         throw new Error(err);
  //     }
  // }
};

export default Auth;
