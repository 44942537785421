import axios from "axios";
import Auth from "./auth";
import apiURL from "configs/configs";
const dayjs = require("dayjs");

const baseUrl = apiURL + "order/";
export class OrderTable {
  constructor(id, data) {
    this.id = id;
    this.datetime = dayjs(data["datetime"]).format("YYYY-MM-DD HH:mm:ss");
    this.objDateTime = dayjs(data["datetime"]);
    this.status = data["status"];
    this.amount = data["amount"];
    this.deliveryCost = data["delivery_cost"];
    this.merchant = data["seller_id"];
    this.address = data["address"];
    this.location = data["location"];
    this.userId = data["user_id"];
    this.userPhoneNumber = data["user_phone_number"];
    this.userName = data["user_name"];
  }
}
export class OrderDetailTable {
  constructor(id, data) {
    this.id = id;
    this.productId = data["product_id"];
    this.merchantId = data["seller_id"];
    this.userId = data["user_id"];
    this.title = data["title"];
    this.mainImage = data["main_image"];
    this.price = data["price"];
  }
}
const OrderData = {
  limit: 10,
  lastVisible: null,
  async getAll(page, filterBy, filterValue) {
    try {
      //apiURL

      let url =
        baseUrl +
        `?page=${page}&filterBy=${filterBy}&filterValue=${filterValue}`;
      let response = await axios.get(encodeURI(url), {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async getOrderDetails(id) {
    try {
      //apiURL
      let response = await axios.get(baseUrl + `${id}`, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async updateStatus(orderId, status) {
    var config = {
      method: "patch",
      url: `${baseUrl}${orderId}/status/${status}`,
      headers: {
        Authorization: Auth.currentUser.accessToken,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch(function (error) {
        return Promise.reject(error.response.data);
      });
  },

  async confirmOrder(orderId, notes) {
    var config = {
      method: "POST",
      url: `${baseUrl}${orderId}`,
      headers: {
        Authorization: Auth.currentUser.accessToken,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ notes: notes }),
    };

    return await axios(config)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch(function (error) {
        return Promise.reject(error.response.data);
      });
  },
};
export default OrderData;
