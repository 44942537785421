import { getFirestore, doc, setDoc } from "firebase/firestore";
import firebaseApp from "components/Firebase/firebase";
import Model from "car-json/ford/model_ford.json";
import apiURL from "configs/configs";
const axios = require("axios").default;
//baseURL
const baseUrl = apiURL + "product/";
const CarData = {
  async set() {
    let db = getFirestore(firebaseApp);
    Model.forEach(async (row) => {
      await setDoc(doc(db, "model", row.id), {
        make_id: row.make_id,
        name: row.name,
      });
    });

    return Promise.resolve(true);
  },
  async getAllCarMake(value) {
    try {
      let response = await axios.get(baseUrl + `car-make?name=${value}`);
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getModel(param) {
    try {
      let response = await axios.get(
        `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeId/${param}?format=json`
      );
      var result = response.data;

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
export default CarData;
