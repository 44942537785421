import { useEffect, useState } from "react";
import { Grid, TextField, MenuItem, Alert, Snackbar } from "@mui/material";

import Localization from "I18n/I18n";
import MerchantsDialog from "./merchants";
import ProductData from "models/product-data";
import { useParams } from "react-router-dom";

const ProductDetails = (props) => {
  let { id } = useParams();
  const { onChange } = props;
  const [alertMsg, setAlertMsg] = useState(<></>);
  const [productFields, setProductFields] = useState({
    name: "",
    price: 0,
    description: "",
    barcode: "",
    product_width: 0,
    product_height: 0,
    product_length: 0,
    product_weight: 0,
    part_number: "",
    sku_number: "",
    category_id: "",
    quantity: 1,
    merchant_id: "",
    merchant_name: "",
  });
  const fields_onChange = (field, value) => {
    let _pFields = { ...productFields }; //First copy product fields
    _pFields[field] = value; // update value
    setProductFields(_pFields); // set product fields.
    onChange(_pFields);
  };

  useEffect(() => {
    ProductData.getById(id)
      .then((result) => {
        let data = result.results;
        setProductFields(data, () => {});
      })
      .catch((error) => {
        setProductFields({});
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="error"
              sx={{ width: "100%", marginRight: "10%" }}
            >
              {Localization.translate["not_available"]}
            </Alert>
          </Snackbar>
        );
      });
  }, [id]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            variant="outlined"
            value={productFields.name}
            label={Localization.translate["product_name"]}
            onChange={(event) => {
              fields_onChange("name", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            variant="outlined"
            value={productFields.price}
            label={Localization.translate["price"]}
            helperText={Localization.translate["price_in_iqd"]}
            error={productFields.price <= 0}
            onChange={(event) => {
              fields_onChange("price", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            label={Localization.translate["quantity"]}
            value={productFields.quantity}
            onChange={(event) =>
              fields_onChange("quantity", event.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            select
            id="select"
            label={Localization.translate["category"]}
            value={productFields.category_id}
            onChange={(event) => {
              fields_onChange("category_id", event.target.value);
            }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>{productFields.category_id}</em>;
              }

              return selected.join(", ");
            }}
          >
            <MenuItem value="AutoParts">
              {Localization.translate["AutoParts"]}
            </MenuItem>
            <MenuItem value="ExteriorAccessories">
              {Localization.translate["ExteriorAccessories"]}
            </MenuItem>
            <MenuItem value="InteriorAccessories">
              {Localization.translate["InteriorAccessories"]}
            </MenuItem>
            <MenuItem value="FluidsChemicals">
              {Localization.translate["FluidsChemicals"]}
            </MenuItem>
            <MenuItem value="Tools">{Localization.translate["Tools"]}</MenuItem>
            <MenuItem value="TruckTowing">
              {Localization.translate["TruckTowing"]}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            multiline
            value={productFields.description}
            variant="outlined"
            label={Localization.translate["description"]}
            error={productFields.description === ""}
            onChange={(event) => {
              fields_onChange("description", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={productFields.barcode}
            variant="outlined"
            label={Localization.translate["barcode"]}
            onChange={(event) => {
              fields_onChange("barcode", event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            multiline
            value={productFields.product_width}
            type="number"
            variant="outlined"
            label={Localization.translate["product_width"]}
            error={productFields.product_width === ""}
            onChange={(event) => {
              fields_onChange("product_width", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            multiline
            value={productFields.product_height}
            type="number"
            variant="outlined"
            label={Localization.translate["product_height"]}
            error={productFields.product_height === ""}
            onChange={(event) => {
              fields_onChange("product_height", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            multiline
            value={productFields.product_length}
            variant="outlined"
            type="number"
            label={Localization.translate["product_length"]}
            error={productFields.product_length === ""}
            onChange={(event) => {
              fields_onChange("product_length", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            multiline
            type="number"
            value={productFields.product_weight}
            variant="outlined"
            label={Localization.translate["product_weight"]}
            error={productFields.product_weight === ""}
            onChange={(event) => {
              fields_onChange("product_weight", event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={productFields.part_number}
            variant="outlined"
            label={Localization.translate["part_number"]}
            onChange={(event) => {
              fields_onChange("part_number", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            value={productFields.sku_number}
            label={Localization.translate["sku_number"]}
            onChange={(event) => {
              fields_onChange("sku_number", event.target.value);
            }}
          />
        </Grid>
      </Grid>
      <MerchantsDialog
        fields_onChange={fields_onChange}
        merchantName={productFields.merchant_name}
      />
      {/* <AutoMerchants/> */}
      {alertMsg}
    </>
  );
};

export default ProductDetails;
