import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Localization from "I18n/I18n";
import Auth from 'models/auth';
import Copyright from 'components/copyright/copyright';

const theme = createTheme();

export default function SignIn() {
  
    // this variable use to contain other message like error message and info message even is dialog.
    // any compoent you like add her and apear in runtime.
    // Pleasae note that this variable hold one item at one operation
    const [ otherComponent, setOtherComponent ] = useState(<></>)

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setOtherComponent(
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true} 
                ><CircularProgress color="inherit" /></Backdrop>
        )
        Auth.signIn(data.get('email'), data.get('password'))
        .then((success)=>{
            setOtherComponent(<></>)
            //for now reload page.
            window.location.reload();
        })
        .catch((error)=>{
            setOtherComponent(
                <Alert severity="error">{Localization.translate[error.message]}</Alert>
            )
        })
    };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {Localization.translate["sign_in"]}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    id="email"
                    label={Localization.translate["email"]}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={Localization.translate["password"]}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        />
                        {/* use it later if we want to one time sign in */}
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                    {otherComponent}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        >
                        {Localization.translate["sign_in"]}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                {Localization.translate["forgot_password"]}
                            </Link>
                        </Grid>
                    </Grid>
            </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}