/* eslint-disable jsx-a11y/img-redundant-alt */
import Localization from "I18n/I18n";
import * as React from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  MenuItem,
  Alert,
  Snackbar,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ProductEnquiry from "models/product-enquiry";
import MerchantsData from "models/merchant-data";

const CardComponent = () => {
  const { state } = useLocation();

  const enquiry = state.row;
  const [merchantList, setMerchantList] = React.useState([]);
  const [selectedMerchant, setSelectedMerchant] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState(<></>);
  const [response, setResponse] = React.useState({
    product_enquiry_id: Number(enquiry.product_enquiry_id),
    price: 0,
    notes: "",
    merchant_id: selectedMerchant,
  });
  const getAllMerchants = () => {
    MerchantsData.getAllMerchants("all", "")
      .then((data) => {
        setMerchantList(data.results);
      })
      .catch((error) => {});
  };
  React.useEffect(() => {
    getAllMerchants();
  }, []);
  const sendResponse = async () => {
    setResponse({ ...response, merchant_id: selectedMerchant });
    setResponse({ ...response, price: Number(response.price) });
    await ProductEnquiry.addResponse(response)
      .then((result) => {
        <Alert
          onClose={() => {
            setAlertMessage(<></>);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {Localization.translate["successfully_added"]}
        </Alert>;
      })
      .catch((error) => {
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={6000}
          onClose={() => {
            setAlertMessage(<></>);
          }}
        >
          <Alert
            onClose={() => {
              setAlertMessage(<></>);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error.message
              ? error.message
              : Localization.translate["added_faield"]}
          </Alert>
        </Snackbar>;
      });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        {enquiry?.product_enquiry_image.map((img) => (
          <Grid item xs={3}>
            <img
              src={img.image_url}
              alt="product image"
              style={{ Width: "150px", height: "150px" }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3> {enquiry.name}</h3>
        </Grid>
        <Grid item xs={12}>
          {Localization.translate["car_make"]} : {enquiry.car_maker}
        </Grid>
        <Grid item xs={12}>
          {Localization.translate["car_model"]} : {enquiry.car_model}
        </Grid>
        <Grid item xs={12}>
          {Localization.translate["car_trim"]} : {enquiry.car_trim}
        </Grid>
        <Grid item xs={12}>
          {Localization.translate["car_year"]} : {enquiry.car_year}
        </Grid>
        <Grid item xs={12}>
          {Localization.translate["description"]} : {enquiry.description}
        </Grid>

        <Grid item xs={12}>
          <h3> {Localization.translate["add_response"]} </h3>
        </Grid>

        <Grid item xs={12}>
          <TextField
            type="number"
            id="price"
            required
            variant="outlined"
            value={response.price}
            label={Localization.translate["price"]}
            onChange={(event) => {
              setResponse({ ...response, price: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="notes"
            required
            variant="outlined"
            value={response.notes}
            label={Localization.translate["notes"]}
            onChange={(event) => {
              setResponse({ ...response, notes: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            select
            id="select"
            label={Localization.translate["choose_merchant"]}
            value={selectedMerchant}
            onChange={(event) => {
              setSelectedMerchant(event.target.value);
            }}
          >
            {merchantList.map((merchant) => (
              <MenuItem key={merchant.merchant_id} value={merchant.merchant_id}>
                {merchant.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={sendResponse}>
            {Localization.translate["send_response"]}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {alertMessage}
        </Grid>
      </Grid>
    </Container>
  );
};
export default CardComponent;
