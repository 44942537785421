import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import 'index.css';
import RoutesConfig from 'configs/routes-config';
import { Grid } from '@mui/material';
import Auth from 'models/auth';
import SignIn from 'pages/sign-in/sign-in';
import Header from 'components/header/header';
import Notification from 'components/Firebase/notification';

// Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
  });
function RTL(props) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}

function IndexPage () {
  
  const [ isLoading, setIsLoading] = useState(true);
  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
  const theme = createTheme(
    { 
      direction: 'rtl',
      typography: {
        fontSize: 15,
        fontFamily: [
          'Noto Kufi Arabic',
        ].join(','),
      },
      palette: {
        delete: createColor('#e55a54'),
      },
    }
  );
  const init = () => {  
    Auth.init((success)=>{
      setIsLoading(false);
      if (success){

      }else{

      }
    })
  }
  useEffect(init,[])
  if (isLoading){
    return <React.StrictMode>
      <ThemeProvider theme={theme}> 
      <Container maxWidth="lg">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh',marginTop:'50vh' }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid> 
      </Container>
      </ThemeProvider>
    </React.StrictMode> 
  }
  if (!Auth.isSignIn){
    return <React.StrictMode>
      <RTL>
        <ThemeProvider theme={theme}>
          <SignIn />
        </ThemeProvider>
      </RTL>
    </React.StrictMode>
  }
  return <React.StrictMode>
    <RTL>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Router>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <Header /> 
              <Notification/>
                <Box
                component="main"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                  }}
                >
                <Toolbar />
                  <RoutesConfig /> 
                </Box>
            </Box>
          </Router>
      </ThemeProvider>
    </RTL>
    
  </React.StrictMode>
}
ReactDOM.render(
  <IndexPage />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
