import * as React from 'react'; 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import RecentOrders from 'pages/recent-orders/recent-orders';



function DashboardContent() {
  
  
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <RecentOrders />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default function Home() {
  return <DashboardContent />;
}