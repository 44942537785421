import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import Localization from "I18n/I18n";
import React from "react";

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: #24292f;
  background: #fff;
  border: 1px solid #d0d7de;
  box-shadow: 0px 2px 24px #DAECFF;

  &:hover {
    border-color: #3399FF;
  }

  &:focus {
    border-color: #3399FF;
    box-shadow: 0 0 0 3px #b6daff
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    border-radius: 12px;
  }
`;

function ConfirmOrderDialog(props) {
  const [notes, setNotes] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const handleDialogClose = () => {
    setIsLoading(false)
    props.onClose();
  };
  const handleConfirmOrder = () => {
    setIsLoading(true)
    props.onConfirm(notes);
  };
  return (
    <StyledDialog  open={props.open} key={props.open}>
      <DialogTitle>{Localization.translate["confirm_order"]}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {Localization.translate["add_order_notes"]} (
          {Localization.translate["optional"]})
        </DialogContentText>
        <br />
        <StyledTextarea
          autoFocus
          color="primary"
          id="notes"
          label="Order Notes"
          placeholder={`${Localization.translate["note"]}...`}
          minRows={4}
          onChange={(e) => setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>الغاء</Button>
        <LoadingButton loading={isLoading} onClick={handleConfirmOrder}>تأكيد الطلبية</LoadingButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default ConfirmOrderDialog;
