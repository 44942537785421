import apiURL from "configs/configs";
import Auth from "./auth";

const axios = require("axios").default;
const baseUrl = apiURL + "enquiry/";
const ProductEnquiry={
    async getAllProductsEnquiry() {
        try {
          //apiURL
         
          let response = await axios.get(encodeURI(baseUrl), {
            headers: {
              authorization: Auth.currentUser.accessToken,
            },
          });
          var result = response.data;
          if (result.error !== 0) {
            return Promise.reject(result);
          }
    
          return Promise.resolve(result);
        } catch (error) {
          return Promise.reject(error.response.data);
        }
      },
      async addResponse(record) {
        try {
          let data = record;
    
          //apiURL
          let response = await axios.post(baseUrl+"response/", data, {
            headers: {
              authorization: Auth.currentUser.accessToken,
            },
          });
          var result = response.data;
          if (result.error !== 0) {
            return Promise.reject({ error: 76, message: "added_failed" });
          }
    
          return Promise.resolve(result);
        } catch (error) {
          return Promise.reject(error.response.data);
        }
      },
}
export default ProductEnquiry