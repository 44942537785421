import React, { useEffect} from "react";

export default function RecentOrders() {

  useEffect(() => {
   
  }, []);

  return (
    <React.Fragment>
      Welcome back
    </React.Fragment>
  );
}
