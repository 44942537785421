/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  Typography,
  Alert,
  Snackbar,
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Container,
  FormControl,
} from "@mui/material";

import MerchantsData from "models/merchant-data";
import Localization from "I18n/I18n";
import { useLocation, useNavigate } from "react-router-dom";
import StaticData from "models/static-data";
import DeliveryCost from "../components/delivery-cost";

const AddMerchants = () => {
  const data = useLocation();
  let merchant = data.state;
  let navigate = useNavigate();

  const [alertMsg, setAlertMsg] = React.useState(<></>);
  const [state, setState] = React.useState({
    name: merchant ? merchant.name : "",
    phone_number: merchant ? merchant.phone_number : "",
    address: merchant ? merchant.address : "",
    secondary_phone_number: merchant ? merchant.secondary_phone_number : "",
    city: merchant ? merchant.city : "",
    delivery_cost: merchant ? merchant.delivery_cost : {},
    location: merchant ? merchant.location : {longitude:0,latitude:0},
  });

  const cities = StaticData.cities;
  const AddMerchant = async () => {
    await MerchantsData.AddMerchant(state)
      .then((result) => {
        setAlertMsg(<></>);

        navigate("/merchants");
      })
      .catch((error) => {
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              {Localization.translate[error.message]}
            </Alert>
          </Snackbar>
        );
      });
  };

  const updateMerchant = async () => {
    let merchantData = state;
    merchantData["merchant_id"] = parseInt(data.state.merchant_id);
    await MerchantsData.updateMerchant(merchantData)
      .then((result) => {
        setAlertMsg(<></>);

        navigate("/merchants");
      })
      .catch((error) => {
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              {Localization.translate[error.message]}
            </Alert>
          </Snackbar>
        );
      });
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setState({
          ...state,
          location: {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          },
        });
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  React.useEffect(() => {
    if (data.state) {
      setState({
        name: merchant.name,
        phone_number: merchant.phone_number,
        address: merchant.address,
        secondary_phone_number: merchant.secondary_phone_number,
        city: merchant.city,
        delivery_cost: merchant?.delivery_cost,
        location: merchant.location,
      });
    }
  }, []);

  const yesBtn = {
    borderRadius: "26px",
    padding: "3px 50px",
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            fullWidth
            required
            variant="outlined"
            value={state.name}
            label={Localization.translate["merchant_name"]}
            onChange={(event) => {
              setState({ ...state, name: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="address"
            fullWidth
            required
            variant="outlined"
            value={state.address}
            label={Localization.translate["merchant_address"]}
            onChange={(event) => {
              setState({ ...state, address: event.target.value });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            type="tel"
            id="phone_number"
            fullWidth
            required
            variant="outlined"
            value={state.phone_number}
            label={Localization.translate["phone_number"]}
            onChange={(event) => {
              setState({ ...state, phone_number: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="tel"
            id="secondary_phone_number"
            fullWidth
            required
            variant="outlined"
            value={state.secondary_phone_number}
            label={
              <Typography>
                {Localization.translate["secondary_phone_number"]}
                <small sx={{ backgroundColor: "#7B7B7B" }}>
                  ( {Localization.translate["optional"]})
                </small>
              </Typography>
            }
            onChange={(event) => {
              setState({
                ...state,
                secondary_phone_number: event.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {Localization.translate.city}
            </InputLabel>
            <Select
              value={state.city}
              label={Localization.translate.city}
              onChange={(event) => {
                setState({ ...state, city: event.target.value });
              }}
            >
              {cities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom component="div">
                {Localization.translate["location"]}:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                id="longitude"
                fullWidth
                required
                variant="outlined"
                value={state?.location?.longitude ?? 0}
                label={Localization.translate["longitude"]}
                onChange={(event) => {
                  setState({
                    ...state,
                    location: {
                      ...state.location,
                      longitude: event.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                id="latitude"
                fullWidth
                required
                variant="outlined"
                value={state?.location?.latitude ?? 0}
                label={Localization.translate["latitude"]}
                onChange={(event) => {
                  setState({
                    ...state,
                    location: {
                      ...state.location,
                      latitude: event.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                style={{ background: "#1976d2", color: "#fff" }}
                onClick={getUserLocation}
              >
                {Localization.translate["get_current_location"]}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <DeliveryCost
          delivery_cost={state.delivery_cost}
          setDeliveryCost={(costs) => {
            setState({ ...state, delivery_cost: costs });
          }}
        />
        <Grid container spacing={2} mt={5}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="error"
              style={yesBtn}
              onClick={() => {
                if (data.state) {
                  updateMerchant();
                } else {
                  AddMerchant();
                }
              }}
            >
              {Localization.translate["save"]}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {alertMsg}
    </Container>
  );
};
export default AddMerchants;
