import ar from "I18n/languages/ar";
import arError from "I18n/languages/ar_error";

const Localization = {
  currentLanguage: {
    id: 1,
    name: "arabic",
    native_name: "عربي",
    code: "ar", //ISO 639-1 Language Codes see more on GETTING-STARTED.md file
    dir: "rtl",
    // icon: LangCh
  },
  availableLanguage: [
    {
      id: 1,
      name: "arabic",
      native_name: "عربي",
      code: "ar", //ISO 639-1 Language Codes see more on GETTING-STARTED.md file
      dir: "rtl",
      // icon: LangCh
    },
    {
      id: 2,
      name: "english",
      native_name: "English",
      code: "en", //ISO 639-1 Language Codes see more on GETTING-STARTED.md file
      dir: "ltr",
      // icon: LangEn
    },
  ],
  translate: Object.assign(ar, arError),
  init() {
    Localization.translate = Object.assign(ar, arError);
    Localization.currentLanguage = this.availableLanguage[0];
  },
  setCurrentLanguage(LangCode) {
    window.location.reload();
  },
};
export default Localization;
