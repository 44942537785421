import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {
  Button,
  Snackbar,
  Alert,
  LinearProgress,
  Typography,
  TextField,
  CardMedia,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useNavigate, useSearchParams } from "react-router-dom";
import Localization from "I18n/I18n";
import ProductData from "models/product-data";
import ProductDiscount from "./components/product-discount";
import MoneyIcon from "@mui/icons-material/Money";
import CategoryIcon from "@mui/icons-material/Category";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

export default function ProductPage() {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [alertMessage, setAlertMessage] = useState(<></>);
  const [search, setSearch] = useState(""); //filterValue
  const [filter, setFilter] = useState(""); //filterBy
  const [page, setPage] = useState(1); //page number
  //Use this to find if user open app in mobile or desktop
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const btnAdd = {
    display: "flex",
    margin: "auto",
    backgroundColor: "#9A1111",
    borderColor: "#9A1111",
    justifyContent: "center",
    padding: "6px",
  };
  const iconStyle = {
    color: "#731F1F",
  };
  const oldPrice = {
    color: "gray",
    textDecoration: "line-through",
    fontSize: "12px",
  };
  const discount = {
    color: "red",
  };
  const handleChangeSearch = (value) => {
    setSearch(value);
    setSearchParams(
      new URLSearchParams({ page: page, search: value, filter: filter })
    );
  };
  const handleChangeFilter = (value) => {
    setFilter(value);
    setSearchParams(
      new URLSearchParams({ page: page, search: search, filter: value })
    );
  };
  const searchBtn = () => {
    getAll();
  };

  const changeAvailable = (event, product) => {
    setIsLoading(true);
    let value = event.target.checked;
    ProductData.available(product.product_id, value)
      .then((result) => {
        setAlertMessage(<></>);
        let _productList = [...productList];
        let _index = _productList.findIndex(
          (item) => item.product_id === product.product_id
        );
        _productList[_index].is_available = value;
        setProductList(_productList);
        setIsLoading(false);
      })
      .catch((error) => {
        setAlertMessage(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMessage(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMessage(<></>);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              {Localization.translate[error.message]}
            </Alert>
          </Snackbar>
        );
        setIsLoading(false);
      });
  };
  const getAll = () => {
    setIsLoading(true);
    let currentParam = Object.fromEntries([...searchParams]);
    if (currentParam.page === undefined) {
      currentParam.page = 1;
      currentParam.search = "";
      currentParam.filter = "";
    }
    //if search input empty
    search === ""
      ? ProductData.getAll(
          currentParam.page,
          currentParam.search,
          currentParam.filter
        )
          .then((result) => {
            let _productList = [...productList];
            if (result.results.length > 0) {
              setAlertMessage(<></>);
              _productList = result.results;
              setProductList(_productList);
              setIsLoading(false);
            } else {
              setProductList([]);
              setAlertMessage(<>{Localization.translate["no_items"]}</>);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setProductList([]);
            setAlertMessage(<>{Localization.translate["no_items"]}</>);
            setIsLoading(false);
          })
      : ProductData.getAll(
          currentParam.page,
          currentParam.search,
          currentParam.filter
        )
          .then((result) => {
            setIsLoading(true);
            let _productList = [...productList];
            if (result.results.length > 0) {
              setAlertMessage(<></>);
              _productList = result.results;
              setProductList(_productList);
              setIsLoading(false);
            } else {
              setProductList([]);
              setAlertMessage(<>{Localization.translate["no_items"]}</>);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setProductList([]);
            setAlertMessage(<>{Localization.translate["no_items"]}</>);
            setIsLoading(false);
          });
  };
  const nextPage = () => {
    setPage(page + 1);
    setSearchParams(
      new URLSearchParams({ page: page + 1, search: search, filter: filter })
    );
  };
  const previousPage = () => {
    if (page >= 2) {
      setPage(page - 1);
      setSearchParams(
        new URLSearchParams({ page: page - 1, search: search, filter: filter })
      );
    }
  };
  useEffect(() => {
    let currentParam = Object.fromEntries([...searchParams]);
    if (currentParam.page !== undefined) {
      setPage(Number(currentParam.page));
      setSearch(currentParam.search);
      setFilter(currentParam.filter);
    }
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (isLoading) {
    return (
      <React.Fragment>
        <LinearProgress />
      </React.Fragment>
    );
  }
  const btnModalStyle = {
    display: "flex",
    fontWeight: "600",
    fontSize: "12px",
    color: "#9A1111",
    borderColor: "#9A1111",
    justifyContent: "center",
    padding: "6px",
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ width: "100%" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={2}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    style={btnModalStyle}
                    fullWidth
                    onClick={() => {
                      navigate("/product/new");
                    }}
                  >
                    <AddIcon />
                    &nbsp;{Localization.translate["add_new_product"]}
                  </Button>
                </Grid>
              </Grid>
              <Grid xs={12} md={10} container item>
                <Grid item xs={12} md={6} sx={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    id="search-input"
                    variant="outlined"
                    value={search}
                    onChange={(event) => handleChangeSearch(event.target.value)}
                    label={
                      Localization.translate["write_what_do_you_want_to_search"]
                    }
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} md={4} sx={{ padding: "10px" }}>
                  <TextField
                    select
                    label={Localization.translate["search_by"]}
                    fullWidth
                    size="small"
                    value={filter}
                    onChange={(event) => handleChangeFilter(event.target.value)}
                  >
                    <MenuItem value={"name"}>
                      {Localization.translate["product_name"]}
                    </MenuItem>
                    <MenuItem value={"category"}>
                      {Localization.translate["category"]}
                    </MenuItem>
                    <MenuItem value={"sku_number"}>
                      {Localization.translate["sku_number"]}
                    </MenuItem>
                    <MenuItem value={"vin"}>
                      {Localization.translate["vin"]}
                    </MenuItem>
                    <MenuItem value={"discount"}>
                      {Localization.translate["discount"]}
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2} sx={{ padding: "10px" }}>
                  <Button
                    variant="contained"
                    style={btnAdd}
                    onClick={searchBtn}
                  >
                    {Localization.translate["search_btn"]}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {isMobile ? (
          <>
            {productList.map((product) => (
              <Grid item xs={12} sm={4} md={3} key={product.id}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                    image={product.main_image}
                    component="img"
                    height="300"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h1"
                      component="div"
                      sx={{ fontSize: 16 }}
                    >
                      {product.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: 14 }}
                    >
                      {product.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <MenuAction product={product} getAll={getAll} />
                    <span style={{ padding: "5px" }} />
                    <FormGroup sx={{ display: "inline" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={product.is_available ? true : false}
                            onChange={(event) => {
                              changeAvailable(event, product);
                            }}
                          />
                        }
                        label={Localization.translate["available"]}
                      />
                    </FormGroup>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </>
        ) : (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={iconStyle}>
                      <Grid sx={{ display: "flex" }}>
                        <Typography marginRight="0.5rem">
                          {Localization.translate["product_name"]}
                        </Typography>
                        <WorkspacePremiumIcon />
                      </Grid>
                    </TableCell>
                    <TableCell style={iconStyle}>
                      <Grid sx={{ display: "flex" }}>
                        <Typography marginRight="0.5rem">
                          {Localization.translate["price"]}
                        </Typography>
                        <MoneyIcon />
                      </Grid>
                    </TableCell>
                    <TableCell style={iconStyle}>
                      <Grid sx={{ display: "flex" }}>
                        <Typography marginRight="0.5rem">
                          {Localization.translate["product_quantity"]}
                        </Typography>
                        <WorkspacePremiumIcon />
                      </Grid>
                    </TableCell>
                    <TableCell style={iconStyle}>
                      <Grid sx={{ display: "flex" }}>
                        <Typography marginRight="0.5rem">
                          {Localization.translate["image"]}
                        </Typography>
                        <WallpaperIcon />
                      </Grid>
                    </TableCell>
                    <TableCell style={iconStyle}>
                      <Grid sx={{ display: "flex" }}>
                        <Typography marginRight="0.5rem">
                          {Localization.translate["category"]}
                        </Typography>
                        <CategoryIcon />
                      </Grid>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productList.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.discount !== null ? (
                          <>
                            <Typography style={oldPrice}>
                              {row.price}
                            </Typography>
                            <Typography style={discount}>
                              {row.discount}
                            </Typography>
                          </>
                        ) : (
                          <Typography>{row.price}</Typography>
                        )}
                      </TableCell>
                      <TableCell>{row.quantity}</TableCell>
                      <TableCell>
                        <CardMedia
                          image={row.main_image}
                          component="img"
                          height="100"
                          alt="green iguana"
                        />
                      </TableCell>
                      <TableCell>{row.category_id}</TableCell>
                      <TableCell>
                        <FormGroup sx={{ display: "inline" }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={row.is_available ? true : false}
                                onChange={(event) => {
                                  changeAvailable(event, row);
                                }}
                              />
                            }
                            label={Localization.translate["available"]}
                          />
                        </FormGroup>
                        <span style={{ padding: "5px" }} />
                        <MenuAction product={row} getAll={getAll} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography style={{ textAlign: "left" }}>
                <Button onClick={previousPage}>
                  {Localization.translate["previous"]}
                </Button>
                <Button onClick={nextPage}>
                  {Localization.translate["next"]}
                </Button>
              </Typography>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          {alertMessage}
        </Grid>
      </Grid>
    </Container>
  );
}

function MenuAction({ product, getAll }) {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [alertMsg, setAlertMsg] = useState(<></>);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelDiscount = () => {
    handleClose();
    setAlertMsg(
      <ProductDiscount
        product={product}
        onClose={() => {
          getAll();
          setAlertMsg(<></>);
        }}
      />
    );
  };
  const options = {
    color: "gray",
    display: "flex",
    lineHeight: "11px",
    fontSize: "30px",
    fontWeight: "700",
    marginTop: "-16px",
    alignItems: "center",
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div style={options}>
          .<br />.<br />.
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/product/edit/" + product.product_id);
          }}
        >
          <Edit />
          &nbsp;{Localization.translate["edit"]}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/product/delete/" + product.product_id);
          }}
        >
          <Delete color="delete" />
          &nbsp;{Localization.translate["delete"]}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handelDiscount();
          }}
        >
          <LocalOfferIcon />
          &nbsp;{Localization.translate["discount"]}
        </MenuItem>
      </Menu>
      {alertMsg}
    </>
  );
}
