import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CardMedia,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Map from "../../components/map/map.js";
import OrderData from "models/order-data";
import Localization from "I18n/I18n";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmOrderDialog from "./confirm-order-dialog.js";

function Title(props) {
  return (
    <Typography
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
      sx={{ ml: 2, mt: 5 }}
    >
      {props.children}
    </Typography>
  );
}

const ConfirmButtonStyled = styled(LoadingButton)({
  background: "linear-gradient(45deg, #5aa1ff 60%, #1976d2 90%)",
  backgroundColor: "#5aa1ff",
  border: 0,
  borderRadius: 5,
  boxShadow: "0 3px 5px 1px rgba(0, 0, 0, .1)",
  color: "white",
  height: 38,
  padding: "0 20px",
  margin: "0",
  fontSize: "0.8em",
  transition: "all 0.5s",
  "&:hover": {
    background: "#1976d2",
    boxShadow: "0 3px 5px 1px rgba(0, 0, 0, .2)",
  },
  "&.MuiLoadingButton-loading": {
    color: "rgba(0, 0, 0, 0.16)",
    background: "rgb(203, 203, 203)",
    boxShadow: "0 3px 5px 1px rgba(0, 0, 0, .1)",
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.16)",
    background: "rgb(203, 203, 203)",
    boxShadow: "0 3px 5px 1px rgba(0, 0, 0, .1)",
  },
});

const oldPrice = {
  color: "gray",
  textDecoration: "line-through",
  fontSize: "12px",
};
const discount = {
  color: "red",
};
export default function OrderDetail() {
  let { id } = useParams();
  const [order, setOrder] = useState({
    location: {
      x: 0,
      y: 0,
    },
  });

  // fields of order table.
  const [orderDetail, setOrderDetail] = useState([]); // rows of order detail table.
  const [isLoading, setIsLoading] = useState(true);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    Localization.translate["order_confirm_failed"]
  );
  const confirmOrderDialogOpen = () => {
    setOpenDialog(true);
  };

  const confirmOrderDialogClose = () => {
    setOpenDialog(false);
  };

  const handleConfirmOrder = (notes) => {
    setConfirmButtonLoading(true);
    OrderData.confirmOrder(id, notes)
      .then((data) => {
        confirmOrderDialogClose();
        if (!Boolean(data.results[0].success)) {
          if (data.results[0].details) {
            setErrorMessage(data.results[0].details);
          }
          setErrorSnackbarOpen(true);

          return;
        }

        setSuccessSnackbarOpen(true);
        init();
      })
      .catch((error) => {
        confirmOrderDialogClose();
        if (error.message) {
          setErrorMessage(error.message);
        }
        setErrorSnackbarOpen(true);
      })
      .finally(() => {
        setConfirmButtonLoading(false);
      });
  };

  const init = () => {
    setIsLoading(true);
    OrderData.getOrderDetails(id)
      .then((data) => {
        setOrder(data.results);
        setOrderDetail(data.results?.order_details);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const confirmOrderDialog = openDialog && (
    <ConfirmOrderDialog
      open={openDialog}
      onConfirm={handleConfirmOrder}
      onClose={confirmOrderDialogClose}
    />
  );

  const confirmOrderSnackbar = () => {
    const timeToClose = 6000;
    const position = { vertical: "top", horizontal: "center" };
    const alertStyles = { width: "100%" };
    return (
      <>
        <Snackbar
          anchorOrigin={position}
          open={successSnackbarOpen}
          onClose={() => setSuccessSnackbarOpen(false)}
          autoHideDuration={timeToClose}
        >
          <Alert severity="success" sx={alertStyles}>
            {Localization.translate["order_confirm_success"]}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={position}
          open={errorSnackbarOpen}
          onClose={() => setErrorSnackbarOpen(false)}
          autoHideDuration={timeToClose}
        >
          <Alert severity="error" sx={alertStyles}>
            {Localization.translate["order_confirm_failed"]}
            <br />
            التفاصيل : {errorMessage}
          </Alert>
        </Snackbar>
      </>
    );
  };
  useEffect(init, [id]);
  if (isLoading) {
    return (
      <Container>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Container>
    );
  }
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid item xs={12} />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>{Localization.translate["order-details"]}</Title>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{Localization.translate["order-ID"]}</TableCell>
                <TableCell>{Localization.translate["date-time"]}</TableCell>
                <TableCell>{Localization.translate["amount"]}</TableCell>
                <TableCell>{Localization.translate["delivery-cost"]}</TableCell>
                <TableCell>{Localization.translate["total-amount"]}</TableCell>
                <TableCell>{Localization.translate["order-status"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{order.order_id}</TableCell>
                <TableCell>{order.create_datetime}</TableCell>
                <TableCell>{order.amount}</TableCell>
                <TableCell>{order.delivery_cost}</TableCell>
                <TableCell>
                  {Number(order.delivery_cost) + Number(order.amount)}
                </TableCell>
                <TableCell>{Localization.translate[order.status]}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>{Localization.translate["user-details"]}</Title>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{Localization.translate["user_ID"]}</TableCell>
                <TableCell>{Localization.translate["user-name"]}</TableCell>
                <TableCell>{Localization.translate["phone-number"]}</TableCell>
                <TableCell>{Localization.translate["address"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{order.user_id}</TableCell>
                <TableCell>{order.user_name}</TableCell>
                <TableCell>{order.user_phone}</TableCell>
                <TableCell>{order.address}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Map latitude={order?.location?.y} longitude={order?.location?.x} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>{Localization.translate["requested-product-details"]}</Title>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{Localization.translate["product_id"]}</TableCell>
                <TableCell>{Localization.translate["product_name"]}</TableCell>
                <TableCell>{Localization.translate["price"]}</TableCell>
                <TableCell>{Localization.translate["quantity"]}</TableCell>
                <TableCell>{Localization.translate["product_image"]}</TableCell>
                <TableCell>{Localization.translate["operation"]}</TableCell>
              </TableRow>
              {orderDetail.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.product_id}</TableCell>
                  <TableCell>{row.product_name}</TableCell>
                  <TableCell>
                    {row.product_discount !== null ? (
                      <>
                        <Typography style={oldPrice}>
                          {row.product_price}
                        </Typography>
                        <Typography style={discount}>
                          {row.product_discount}
                        </Typography>
                      </>
                    ) : (
                      <Typography>{row.product_price}</Typography>
                    )}
                  </TableCell>
                  <TableCell>{row.quantity.toLocaleString()}</TableCell>
                  <TableCell>
                    <CardMedia
                      image={row.product_main_image}
                      style={{ height: 100, width: 100 }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid item xs={12} sx={{paddingX:1,paddingY:0.5}}>
            <ConfirmButtonStyled
              fullWidth
              disabled={order.status === "inProgress"}
              onClick={() => confirmOrderDialogOpen()}
              loading={confirmButtonLoading}
            >
              {Localization.translate["confirm_order"]}
            </ConfirmButtonStyled>
            {confirmOrderDialog}
            {confirmOrderSnackbar()}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
