import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";

import StorageFiles from "./storage-files";
import apiURL from "configs/configs";
import Auth from "./auth";

const axios = require("axios").default;

export class ProductImageTable {
  constructor(id, data) {
    this.id = id;
    this.path = data["image_path"];
    this.full_path = StorageFiles.getFullURL(data["image_path"]);
    this.product_id = data["product_id"];
  }
  set(id, data) {
    this.id = id;
    this.path = data["image_path"];
    this.full_path = data["image_url"];
    this.product_id = data["product_id"];
  }
}

//Default export here
const ProductImageData = {
  /**
   * Request API endpoint to get all product image
   * @param {*} productId
   * @returns
   */
  async getByProductId(productId) {
    try {
      //apiURL
      let response = await axios.get(apiURL + `image/${productId}`);
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  /**
   * Upload Image to firebase storage and request API to save URL to database.
   * @param {*} image
   * @param {*} id
   * @returns
   */
  async add(image, id) {
    return new Promise((resolve, reject) => {
      try {
        /**
         * Upload Image to firebase storage
         */
        let imagePath = "product/" + Date.now() + image.name;
        const storage = getStorage();
        const storageRef = ref(storage, imagePath);
        const uploadTask = uploadBytesResumable(storageRef, image);
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // switch (snapshot.state) {
            //   case 'paused':
            //     //Upload is paused
            //     break;
            //   case 'running':
            //     //Upload is running
            //     break;
            // }
          },
          (error) => {
            // Handle unsuccessful uploads
            reject("error_in_upload");
          },
          async () => {
            /**
             * After upload successfull send image path to API
             */
            let data = {
              image_path: imagePath,
              image_url: StorageFiles.getFullURL(imagePath),
            };
            let response = await axios.post(apiURL + `image/${id}`, data, {
              headers: {
                authorization: Auth.currentUser.accessToken,
              },
            });
            var result = response.data;
            if (result.error !== 0) {
              return reject(result);
            }
            resolve(data);
          }
        );
      } catch (error) {
        reject("error_in_upload");
      }
    });
  },
  /**
   * Use to set main image for the product.
   * @param {*} ImagePath required
   * @param {*} productId required
   * @returns
   */
  async setMainImage(imageData, productId) {
    try {
      //apiURL
      let response = await axios.patch(
        apiURL + `image/${productId}/main`,

        imageData,
        {
          headers: {
            authorization: Auth.currentUser.accessToken,
          },
        }
      );
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  /**
   * Use to delete image from firebase and API
   * @param {*} productImage object contain
   * {
   *  image_path,
   *  image_url,
   *  product_id,
   *  product_image_id
   * }
   * @returns true if success
   */
  async delete(productImage) {
    /**
     * Step 1: delete image from storage
     */
    try {
      const storage = getStorage();
      const desertRef = ref(storage, productImage.image_path);
      await deleteObject(desertRef);
    } catch (error) {
      // switch (error.code) {
      //   case "permission-denied":
      //     return Promise.reject({ error: 11, message: "permission_denied" });
      //   default:
      //     return Promise.reject({ error: 11, message: "unknown_error" });
      // }
    }
    /**
     * Step 2: delete image from API
     */
    try {
      let response = await axios.delete(
        apiURL + `image/${productImage.product_image_id}`,
        {
          data: productImage,
          headers: {
            authorization: Auth.currentUser.accessToken,
          },
        }
      );
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject({
        error: 11,
        message: "unknown_error",
        details: error,
      });
    }
  },
};

export default ProductImageData;
