import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ProductData from "models/product-data";
import Localization from "I18n/I18n";
import { Alert } from "@mui/material";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function ProductDiscount({ onClose, product }) {
  const [discountValue, setDiscountValue] = useState(0);
  const [alert, setAlert] = useState(<></>);

  const discount = async () => {
    await ProductData.discount(product.product_id, Number(discountValue))
      .then((result) => {
        setAlert(
          <Alert severity="success">
            {Localization.translate["discount_done"]}
          </Alert>
        );
      })
      .catch((error) => {
        setAlert(
          <Alert severity="error">
            {Localization.translate["unknown_error"]}
          </Alert>
        );
      });

    onClose();
  };
  return (
    <>
      <Dialog open={true}>
        <BootstrapDialogTitle onClose={onClose}>
          {Localization.translate["discount"]}
        </BootstrapDialogTitle>
        <DialogContent>
          <TextField
            placeholder={Localization.translate["price_after_discount"]}
            autoFocus
            fullWidth
            margin="dense"
            id="price"
            variant="standard"
            label={Localization.translate["please_enter_new_price"]}
            value={discountValue}
            onChange={(event) => {
              setDiscountValue(event.target.value);
            }}
          />
        </DialogContent>
        {alert}
        <DialogActions>
          <Button
            variant="contained"
            onClick={discount}
            sx={{ width: "100%" }}
          >
            {Localization.translate["save"]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
