import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Autocomplete,
  Alert,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Localization from "I18n/I18n";
import { Button } from "@mui/material";
import CarData from "models/car-data";
import ProductData from "models/product-data";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function ProductCompatible(props) {
  const { productId } = props;
  const [alertMsg, setAlertMsg] = useState(<></>);
  const [arrProductCompatible, setArrProductCompatible] = useState([]);

  const btnModalStyle = {
    display: "flex",
    fontWeight: "600",
    fontSize: "12px",
    color: "#9A1111",
    borderColor: "#9A1111",
    justifyContent: "center",
  };

  const openNew = () => {
    setAlertMsg(
      <AddProductCompatible
        productId={productId}
        onClose={(value) => {
          if (value !== null) {
            let _arr = [...arrProductCompatible];
            _arr.push(value);
            setArrProductCompatible(_arr);
          }
          setAlertMsg(<></>);
        }}
      />
    );
  };
  const delete_onClick = async (product_fit_id) => {
    await ProductData.deleteCarFit(productId, product_fit_id)
      .then((result) => {
        setAlertMsg(<></>);
        setArrProductCompatible(
          arrProductCompatible.filter(
            (item) => item.product_fit_id !== product_fit_id
          )
        );
      })
      .catch((error) => {
        setAlertMsg(<Alert severity="error"> {Localization.translate["delete_faield"]}</Alert>);
      });
  
  };
  useEffect(() => {
    ProductData.getCarFit(productId)
      .then((result) => 
      {setArrProductCompatible(result.results) ;  setAlertMsg(<></>);})
      .catch((error) => {
        setAlertMsg(
          <Alert severity="error">
            {Localization.translate["car_fit_not_available"]}
          </Alert>
        );
      });
  }, [productId]);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {Localization.translate["product_compatible"]}
          </Typography>
          <Button variant="outlined" style={btnModalStyle} onClick={openNew}>
            <AddIcon />
            &nbsp;{Localization.translate["add_new_car_compatible"]}
          </Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">
                    {Localization.translate["car_make"]}
                  </TableCell>
                  <TableCell align="right">
                    {Localization.translate["car_model"]}
                  </TableCell>
                  <TableCell align="right">
                    {Localization.translate["car_trim"]}
                  </TableCell>
                  <TableCell align="right">
                    {Localization.translate["from_year"]}
                  </TableCell>
                  <TableCell align="right">
                    {Localization.translate["to_year"]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arrProductCompatible.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{row.make_name}</TableCell>
                    <TableCell align="center">{row.model_name}</TableCell>
                    <TableCell align="center">{row.trim}</TableCell>
                    <TableCell align="center">{row.from_year}</TableCell>
                    <TableCell align="center">{row.to_year}</TableCell>
                    <TableCell>
                      <Button
                        fullWidth
                        variant="outlined"
                        size="small"
                        style={btnModalStyle}
                        onClick={() => {
                          delete_onClick(row.product_fit_id);
                        }}
                      >
                        {Localization.translate["delete"]}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {alertMsg}
    </Box>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
function AddProductCompatible(props) {
  const { productId, onClose } = props;
  const [carMakeList, setCarMakeList] = useState([]);
  const [carModelList, setCarModelList] = useState([]);
  const [years, setYears] = useState([]);
  const [alertMsg, setAlertMsg] = React.useState(<></>);
  const [arrProductCompatible, setProductCompatiable] = useState({
    product_fit_id: 0,
    make_id: "",
    make_name: "",
    model_id: "",
    model_name: "",
    trim: "",
    from_year: "",
    to_year: "",
  });

  const carMake_onChange = (value) => {
    let _pc = { ...arrProductCompatible };
    _pc["make_id"] = value.make_id;
    _pc["make_name"] = value.make_name;
    setProductCompatiable(_pc);
    if (value.length <= 0) {
      return;
    }
    //Get car models for this maker
    CarData.getModel(value.make_id)
      .then((result) => {
        setCarModelList(result.Results);
      })
      .catch((error) => {
        setCarModelList([]);
        setAlertMsg(
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={() => {
              setAlertMsg(<></>);
            }}
          >
            <Alert
              onClose={() => {
                setAlertMsg(<></>);
              }}
              severity="error"
              sx={{ width: "100%" }}
            >
              {Localization.translate["not_available"]}
            </Alert>
          </Snackbar>
        );
      });
  };
  const carModel_onChange = (value) => {
    if (value.Model_ID){
      let _pc = { ...arrProductCompatible };
      _pc["model_id"] = value.Model_ID;
      _pc["model_name"] = value.Model_Name;
      setProductCompatiable(_pc);
    }
  };
  const carTrim_onChange = (event) => {
    let _pc = { ...arrProductCompatible };
    _pc["trim"] = event.target.value;
    setProductCompatiable(_pc);
  };
  const carFromYear_onChange = (event) => {
    let _pc = { ...arrProductCompatible };
    _pc["from_year"] = event.target.value;
    setProductCompatiable(_pc);
  };
  const carToYear_onChange = (event) => {
    let value = event.target.value;
    if (value === "0") {
      return;
    }
    let _pc = { ...arrProductCompatible };
    _pc["to_year"] = value;
    setProductCompatiable(_pc);
  };
  const addToList_onClick = () => {
    /**
     * Add car compatible to this product
     */
    ProductData.addProductFit(productId, arrProductCompatible)
      .then((results) => {
        let _pc = { ...arrProductCompatible };
        _pc.product_fit_id = results.product_fit_id;
        onClose(_pc);
      })
      .catch((error) => {
        //display error message
      });
  };
  useEffect(() => {
    let arrYears = [];
    let now = new Date();
    let year = now.getFullYear();
    for (let y = 2000; y <= year; y++) {
      arrYears.push(y);
    }
    setYears(arrYears);
  }, []);

  return (
    <Dialog fullWidth maxWidth="md" open={true}>
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          onClose(null);
        }}
      >
        {Localization.translate["add_new_car_compatible"]}
      </BootstrapDialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ padding: "10px" }}>
          {/* set car maker */}
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              fullWidth
              options={carMakeList}
              getOptionLabel={(option) => option.make_name}
              onChange={(event, newValue) => {
                carMake_onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(event) => {
                    //Search car maker
                    if (event.target.value) {
                      CarData.getAllCarMake(event.target.value).then(
                        (result) => {
                          setCarMakeList(result["results"]);
                        }
                      );
                    }
                  }}
                  label={Localization.translate["car_make"]}
                />
              )}
            />
          </Grid>
          {/* set car model  */}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              disablePortal
              options={carModelList}
              getOptionLabel={(option) => option.Model_Name}
              onChange={(event, newValue) => {
                carModel_onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Localization.translate["car_model"]}
                />
              )}
            />
            {alertMsg}
          </Grid>
          {/* enter car trim */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label={Localization.translate["car_trim"]}
              variant="outlined"
              onChange={carTrim_onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  name="from_year"
                  label={Localization.translate["from_year"]}
                  onChange={carFromYear_onChange}
                >
                  <MenuItem value="0">
                    {Localization.translate["none"]}
                  </MenuItem>
                  {years.map((row, index) => (
                    <MenuItem key={index} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  name="to_year"
                  label={Localization.translate["to_year"]}
                  onChange={carToYear_onChange}
                >
                  <MenuItem value="0">
                    {Localization.translate["none"]}
                  </MenuItem>
                  {years.map((row, index) => (
                    <MenuItem key={index} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={addToList_onClick}>
              <AddIcon />
              &nbsp;{Localization.translate["add_to_list"]}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
