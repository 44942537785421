// import { getStorage, ref, getDownloadURL } from "firebase/storage";

// import { generatePath } from "react-router-dom";

const StorageFiles = {
    getURL(path) {
        return new Promise((resolve, reject) => {
            let url = "https://firebasestorage.googleapis.com/v0/b/auto-spare-iq.appspot.com/o/" + encodeURIComponent(path) +"?alt=media"
            resolve(url)
            // Create a reference to the file we want to download
            // const storage = getStorage();
            // const starsRef = ref(storage, path);
            // getDownloadURL(starsRef)
            //     .then((url) => {
            //         // Insert url into an <img> tag to "download"
            //         return resolve(url);
            //     })
            //     .catch((error) => { 
            //         return reject(error);
            //         // A full list of error codes is available at
            //         // https://firebase.google.com/docs/storage/web/handle-errors
            //         // switch (error.code) {
            //         //     case 'storage/object-not-found':
            //         //         // File doesn't exist
            //         //         break;
            //         //     case 'storage/unauthorized':
            //         //         // User doesn't have permission to access the object
            //         //         break;
            //         //     case 'storage/canceled':
            //         //         // User canceled the upload
            //         //         break;

            //         //     // ...

            //         //     case 'storage/unknown':
            //         //         // Unknown error occurred, inspect the server response
            //         //         break;
            //         // }
            //     });
        })
    },
    getFullURL(image){
        let url = "https://firebasestorage.googleapis.com/v0/b/auto-spare-iq.appspot.com/o/" + encodeURIComponent(image) +"?alt=media"
        return url;
    }
}
export default StorageFiles;