import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Localization from "I18n/I18n";
import DeleteMerchant from "./delete-merchant";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PlaceIcon from "@mui/icons-material/Place";
import { Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

function Title(props) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}

export default function ListMerchants({ getAllMerchants, merchantList }) {
  let navigate = useNavigate();
  const iconStyle = {
    color: "#731F1F",
  };
  const cancelStyle = {
    display: "flex",
    fontWeight: "600",
    fontSize: "12px",
    color: "#731F1F",
    justifyContent: "end",
  };
  return (
    <React.Fragment>
      <Title>{Localization.translate["list_merchant"]}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={iconStyle}>
              <Grid item sx={{ display: "flex" }}>
                <Typography marginRight="0.5rem">
                  {Localization.translate["merchant_name"]}
                </Typography>
                <PermIdentityIcon />
              </Grid>
            </TableCell>
            <TableCell style={iconStyle}>
              <Grid item sx={{ display: "flex" }}>
                <Typography marginRight="0.5rem">
                  {Localization.translate["merchant_phone"]}
                </Typography>
                <LocalPhoneIcon />
              </Grid>
            </TableCell>
            <TableCell style={iconStyle}>
              <Grid item sx={{ display: "flex" }}>
                <Typography marginRight="0.5rem">
                  {Localization.translate["merchant_address"]}
                </Typography>
                <PlaceIcon />
              </Grid>
            </TableCell>
            <TableCell style={iconStyle}>
              <Grid item sx={{ display: "flex" }}>
                <Typography marginRight="0.5rem">
                  {Localization.translate["merchant_update"]}
                </Typography>
              </Grid>
            </TableCell>
            <TableCell style={iconStyle}>
              <Grid item sx={{ display: "flex" }}>
                <Typography marginRight="0.5rem">
                  {Localization.translate["merchant_delete"]}
                </Typography>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {merchantList.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>

              <TableCell>{row.phone_number}</TableCell>
              <TableCell>{row.address}</TableCell>
              <TableCell>
                {/* <UpdateMerchant row={row} getAllMerchants={getAllMerchants} /> */}
                <IconButton
        aria-label="add an alarm"
 onClick={()=>navigate(`/merchants/update/${row.merchant_id}`,{state:row})}
        style={cancelStyle}
      >
        <EditIcon />
      </IconButton>
              </TableCell>
              <TableCell>
                <DeleteMerchant row={row} getAllMerchants={getAllMerchants} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
