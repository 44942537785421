import apiURL from "configs/configs";
import Auth from "./auth";
const axios = require("axios").default;
const baseUrl = apiURL + "merchant/";
export class MerchantTable {
  constructor(id, data) {
    this.id = id;
    this.name = data["name"];
    this.address = data["address"];
    this.phone_number = data["phone_number"];
    this.secondary_phone_number = data["secondary_phone_number"];
  }
}
const MerchantsData = {
  limit: 5,
  lastVisible: null,
  async AddMerchant(data) {
    try {
      //apiURL
      let response = await axios.post(baseUrl, data, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject({ error: 76, message: "added_failed" });
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async getAllMerchants(page, search) {
    try {
      //apiURL
      let url = baseUrl + `?page=${page}&filterValue=${search}`;
      let response = await axios.get(encodeURI(url), {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async deleteMerchant(id) {
    try {
      //apiURL
      let response = await axios.delete(baseUrl + `${id}`, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async getOneMerchant(id) {
    try {
      //apiURL
      let response = await axios.get(baseUrl + `${id}`, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject(result);
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async updateMerchant(data) {
    try {
      //apiURL
      let response = await axios.patch(baseUrl, data, {
        headers: {
          authorization: Auth.currentUser.accessToken,
        },
      });
      var result = response.data;
      if (result.error !== 0) {
        return Promise.reject({ error: 76, message: "update_failed" });
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
export default MerchantsData;
