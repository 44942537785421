/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Localization from "I18n/I18n";
import { TextField, Typography } from "@mui/material";
import ListMerchants from "./components/listMerchants";
import { useEffect, useState } from "react";
import { LinearProgress, Button } from "@mui/material";
import MerchantsData from "models/merchant-data";
import Alert from "@mui/material/Alert";
import { useNavigate, useSearchParams } from "react-router-dom";

function DashboardContent() {
  let navigate = useNavigate();
  const [merchantList, setMerchantList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noMoreItem, setNoMoreItem] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [alertMessage, setAlertMessage] = useState(<></>);

  let [searchParams, setSearchParams] = useSearchParams();

  const getAll = () => {
    setIsLoading(true);
    setAlertMessage(<></>);
    if (noMoreItem) {
      return;
    }

    let currentParam = Object.fromEntries([...searchParams]);
    if (currentParam.page === undefined) {
      currentParam.page = 1;
      currentParam.search = "";
    }
    MerchantsData.getAllMerchants(
      Number(currentParam.page),
      currentParam.search
    )
      .then((data) => {
        // put longitude and latitude in the merchant object as location property
        data.results.forEach((merchant) => {
          merchant.location = {
            latitude: merchant.latitude,
            longitude: merchant.longitude,
          };
        });
        setMerchantList(data.results);
        setAlertMessage(<></>);
        setIsLoading(false);
      })
      .catch((error) => {
        setMerchantList([]);
        setIsLoading(false);
        setAlertMessage(
          <Alert severity="error">{Localization.translate["no_items"]}</Alert>
        );
        if (error.message === "no_items") {
          setNoMoreItem(true);
        }
      });
  };
  const searchMerchants = () => {
    search !== ""
      ? setSearchParams(new URLSearchParams({ page: "all", search: search }))
      : setSearchParams(new URLSearchParams({ page: 1, search: search }));
  };
  useEffect(() => {
    let currentParam = Object.fromEntries([...searchParams]);
    if (currentParam.page !== undefined) {
      setPage(Number(currentParam.page));
      setSearch(currentParam.search);
    }

    getAll();
  }, [searchParams]);

  const nextPage = () => {
    setPage(page + 1);
    setSearchParams(new URLSearchParams({ page: page + 1, search: search }));
  };
  const previousPage = () => {
    if (page >= 2) {
      setPage(page - 1);
      setSearchParams(new URLSearchParams({ page: page - 1, search: search }));
    }
  };
  if (isLoading) {
    return (
      <React.Fragment>
        <LinearProgress />
      </React.Fragment>
    );
  }
  const btnAdd = {
    display: "flex",
    margin: "auto",
    backgroundColor: "#9A1111",
    borderColor: "#9A1111",
    justifyContent: "center",
    padding: "6px",
  };
  const btnModalStyle = {
    display: "flex",
    fontWeight: "600",
    fontSize: "12px",
    color: "#9A1111",
    borderColor: "#9A1111",
    justifyContent: "center",
    padding: "0.5rem",
    margin: "0.5rem",
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ width: "100%" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={3} sx={{ marginRight: "0.5rem" }}>
                <Button
                  onClick={() => {
                    navigate("/merchants/add");
                  }}
                  variant="outlined"
                  style={btnModalStyle}
                  fullWidth
                >
                  {Localization.translate["add_merchant"]}
                </Button>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12} md={8} sx={{ padding: "10px" }}>
                    <TextField
                      sx={{
                        display: "flex",
                        margin: "auto",
                        justifyContent: "flex-end",
                      }}
                      fullWidth
                      id="search-input"
                      variant="outlined"
                      label={Localization.translate["search _about_merchant"]}
                      size="small"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ padding: "10px" }}>
                    <Button
                      variant="contained"
                      style={btnAdd}
                      onClick={() => searchMerchants()}
                    >
                      {Localization.translate["search_btn"]}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <ListMerchants
              merchantList={merchantList}
              getAllMerchants={getAll}
            />
            <Typography style={{ textAlign: "left" }}>
              <Button onClick={previousPage}>
                {Localization.translate["previous"]}
              </Button>
              <Button onClick={nextPage}>
                {Localization.translate["next"]}
              </Button>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {alertMessage}
          {noMoreItem}
        </Grid>
      </Grid>
    </Container>
  );
}

export default function MerchantPage() {
  return <DashboardContent />;
}
