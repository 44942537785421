/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Localization from "I18n/I18n";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import StaticData from "models/static-data";

/**
 * Render table based on citiesDeliveryFee object.
 * The citiesDeliveryFee must be object not array.
 * @param {{citiesDeliveryFee, onChange}} props
 * @returns 
 */
function DeliveryCostTable(props) {
  const {citiesDeliveryFee, onChange} = props;

  const deleteCost = (item) => { 
    if (typeof onChange === "function"){
      let _citiesDeliveryFee = {...citiesDeliveryFee};
      Reflect.deleteProperty(_citiesDeliveryFee, item);
      onChange(_citiesDeliveryFee);
    }
  };

  if (typeof citiesDeliveryFee !== "object") {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {Localization.translate["city"]}
              </TableCell>
              <TableCell align="center">
                {Localization.translate.delivery_cost}
              </TableCell>
              <TableCell align="center">
                {Localization.translate.delete}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow >
                <TableCell align="center" colSpan={3}>{Localization.translate.no_data}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  if (Array.isArray(citiesDeliveryFee)){
    return (
      <TableRow>
        <TableCell>object render error</TableCell>
      </TableRow>
    );
  }
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              {Localization.translate["city"]}
            </TableCell>
            <TableCell align="center">
              {Localization.translate.delivery_cost}
            </TableCell>
            <TableCell align="center">
              {Localization.translate.delete}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(citiesDeliveryFee).map((item, index) => (
            <TableRow key={item}>
              <TableCell align="center">{item}</TableCell>
              <TableCell align="center">{citiesDeliveryFee[item]}</TableCell>
              <TableCell align="center">
                {" "}
                <IconButton aria-label="add an alarm">
                  <DeleteIcon onClick={() => deleteCost(item)} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default function DeliveryCost({ delivery_cost, setDeliveryCost }) {
  const [cost, setCost] = React.useState("");
  const [selectedCity, setSelectedCity] = React.useState("");
  const [citiesDeliveryFee, setCitiesDeliveryFee] = React.useState(
    delivery_cost ? delivery_cost : []
  );
  const cities = StaticData.cities;
  
  const addDeliveryCost = () => {
    let _citiesDeliveryFee = { ...citiesDeliveryFee };
    _citiesDeliveryFee[selectedCity] = cost;
    setCitiesDeliveryFee(_citiesDeliveryFee);
  };
  const clearDeliveryCost = () => {
    setCitiesDeliveryFee({});
  }

  const yesBtn = {
    borderRadius: "26px",
    padding: "3px 50px",
  };
  React.useEffect(() => {
    setDeliveryCost(citiesDeliveryFee);
  }, [citiesDeliveryFee]);

  return (
    <Grid container space={2}>
      <h5>{Localization.translate.delivery_cost_title}</h5>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {Localization.translate.city}
            </InputLabel>
            <Select
              required
              value={selectedCity}
              label={Localization.translate.city}
              onChange={(event) => {
                setSelectedCity(event.target.value);
              }}
            >
              {cities.map((city) => (
                <MenuItem key={city} value={city}>{city}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            variant="outlined"
            value={cost}
            label={Localization.translate.delivery_cost}
            onChange={(event) => {
              setCost(event.target.value);
            }}
          />
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="error"
              style={yesBtn}
              onClick={() => {
                addDeliveryCost();
              }}
            >
              {Localization.translate.addDeliveryCost}
            </Button>
            &nbsp;
            <Button
              variant="outlined"
              color="error"
              style={yesBtn}
              onClick={() => {
                clearDeliveryCost();
              }}
            >
              {Localization.translate.clear_delivery_cost}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DeliveryCostTable citiesDeliveryFee={citiesDeliveryFee} onChange={(newValue)=>{setCitiesDeliveryFee(newValue)}}/>
        </Grid>
      </Grid>
    </Grid>
  );
}
